import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Signin from "./Signin";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Home from "./Home";
import Partners from "./Partners";
import PartnersCreate from "./PartnersCreate";
import PartnersEdit from "./PartnersEdit";
import PartnersCharts from "./PartnersChart";
import Exclusives from "./Exclusives";
import ExclusivesCreate from "./ExclusivesCreate";
import ExclusivesEdit from "./ExclusivesEdit";
import Offers from "./Offers";
import OffersCreate from "./OffersCreate";
import OffersEdit from "./OffersEdit";
import Orders from "./Orders";
import OrdersEdit from "./OrdersEdit";
import OrdersCreate from "./OrdersCreate";
import Protected from "./components/Protected";
import Admins from "./Admins"
import AdminsEdit from "./AdminsEdit"
import AdminsCharts from "./AdminsCharts"
import ExclusivesAdmins from "./ExclusivesAdmins";
import OffersAdmins from "./OffersAdmins";
import OrdersAdmins from "./OrdersAdmins";

const Routing = () => {
  return (
    <Routes>
      <Route path="/signin" element={<Signin />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route path="/reset_password" element={<ResetPassword />} />
      <Route path="/" element={<Protected Component={Home} />} />
      <Route path="/partners" element={<Protected Component={Partners} />} />
      <Route
        path="/partners/create"
        element={<Protected Component={PartnersCreate} />}
      />
      <Route
        path="/partners/edit/:id"
        element={<Protected Component={PartnersEdit} />}
      />
      <Route
        path="/partners/view/chart/:id"
        element={<Protected Component={PartnersCharts} />}
      />
      <Route
        path="/exclusives"
        element={<Protected Component={Exclusives} />}
      />
      <Route
        path="/exclusives/create"
        element={<Protected Component={ExclusivesCreate} />}
      />
      <Route
        path="/exclusives/edit/:id"
        element={<Protected Component={ExclusivesEdit} />}
      />
      <Route path="/offers/:who" element={<Protected Component={Offers} />} />
      <Route
        path="/offers/create/:id/:partner_code"
        element={<Protected Component={OffersCreate} />}
      />
      <Route path="/orders/:who" element={<Protected Component={Orders} />} />
      <Route
        path="/orders/edit/:id"
        element={<Protected Component={OrdersEdit} />}
      />
      <Route
        path="/offers/edit/:id"
        element={<Protected Component={OffersEdit} />}
      />
      <Route
        path="/orders/create/:id"
        element={<Protected Component={OrdersCreate} />}
      />
      <Route path="/admins" element={<Protected Component={Admins} />} />
      <Route
        path="/admins/edit/:id"
        element={<Protected Component={AdminsEdit} />}
      />
      <Route
        path="/admins/view/chart/:id"
        element={<Protected Component={AdminsCharts} />}
      />
       <Route
        path="/exclusives/admins"
        element={<Protected Component={ExclusivesAdmins} />}
      />
      <Route
        path="/offers/admins/:who"
        element={<Protected Component={OffersAdmins} />}
      />
      <Route
        path="/orders/admins/:who"
        element={<Protected Component={OrdersAdmins} />}
      />
    </Routes>
  );
};

export default Routing;
