import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Box from "@mui/material/Box";
import Signin from "./Signin";
import { useAuth } from "./AuthContext";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Speedometer from "react-d3-speedometer";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  ReferenceLine,
} from "recharts";
import API from "./config/API";

export default function PartnersCharts() {
  const { isLoggedIn, setLoader, unsetLoader } = useAuth();
  const { id } = useParams();
  const [formData, setFormData] = useState(null);
  const [lineChartData, setLineChartData] = useState([]);
  const [lineChartDataReference, setLineChartDataReference] = useState(0);
  const [additionalTextOffers, setAdditionalTextOffers] = useState("");
  const [additionalTextOrders, setAdditionalTextOrders] = useState("");

  useEffect(() => {
    const fetchPartnerData = async () => {
      try {
        setLoader();
        const response = await API.get(`/dashboard/partner/${id}`);
        const response2 = await API.get(`/dashboard_partner_offers/${id}`);
        unsetLoader();
        console.log(response.data.data);
        console.log(response2.data.data);
        setFormData(response.data.data);
        setLineChartData(response2.data.data.data);
        setLineChartDataReference(parseFloat(response2.data.data.reference));
        calcOffers(response.data.data.offers_value,response.data.data.annual_offers_budget)
        calcOrders(response.data.data.orders_value,response.data.data.annual_orders_budget)
      } catch (error) {
        unsetLoader();
        console.log("ERRROR");
        console.error("Error fetching partner data:", error);
      }
    };
    fetchPartnerData();
  }, []);

  const calcOffers = async (current, budget) => {
    let additionalTextOffers = "";
    
    if (current >= budget) {
      additionalTextOffers = "Budget raggiunto!"; 
    }
  
    setAdditionalTextOffers(additionalTextOffers);
  };
  

  const calcOrders = async (current, budget) => {
    let additionalTextOrders = "";
    
    if (current >= budget) {
      additionalTextOrders = "Budget raggiunto!";
    }
  
    setAdditionalTextOrders(additionalTextOrders);
  };

  if (!isLoggedIn) return <Signin />;

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <h1>
            Statistiche Partner {formData && formData.name}{" "}
            {formData && formData.lastname}
          </h1>
          <Box sx={{ marginBottom: "20px" }}>&nbsp;</Box>
          <Grid container spacing={40} justifyContent="center">
            <Grid item>
              <Box textAlign="center">
                <h2>Saturazione Budget Offerte</h2>
                {formData && (
                  <>
                  <Speedometer
                    value={Math.min(parseFloat(formData.offers_value), parseFloat(formData.annual_offers_budget))}
                    minValue={0}
                    maxValue={parseFloat(formData.annual_offers_budget)}
                    needleColor="black"
                    startColor="red"
                    segments={5}
                    endColor="green"
                    textColor="black"
                    width={600}
                    height={400}
                  />
                  <p style={{ fontSize: "18px", fontWeight: "bold" }}>{additionalTextOffers}</p>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item>
              <Box textAlign="center">
                <h2>Saturazione Budget Ordini</h2>
                {formData && (
                  <>
                  <Speedometer
                    value={Math.min(parseFloat(formData.orders_value), parseFloat(formData.annual_orders_budget))}
                    minValue={0}
                    maxValue={parseFloat(formData.annual_orders_budget)}
                    needleColor="black"
                    startColor="red"
                    segments={5}
                    endColor="green"
                    textColor="black"
                    width={600}
                    height={400}
                  />
                  <p style={{ fontSize: "18px", fontWeight: "bold" }}>{additionalTextOrders}</p>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </div>
      </Box>
      <Box sx={{ marginBottom: "50px" }}>&nbsp;</Box>
      <Box>
        <LineChart
          width={1600}
          height={600}
          data={lineChartData}
          margin={{
            top: 80,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis dataKey="value" domain={[0, lineChartDataReference]} />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            strokeWidth={2}
          />
          <ReferenceLine
            y={lineChartDataReference}
            label="Obiettivo"
            stroke="red"
            strokeDasharray="3 3"
          />
          <text x={800} y={40} textAnchor="middle" fontSize={20}>
            Saturazione Budget Offerte per Mese
          </text>
        </LineChart>
      </Box>
      <ToastContainer />
    </div>
  );
}
