import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Signin from "./Signin";
import { useAuth } from "./AuthContext";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox
} from "@mui/material";
import API from "./config/API";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useToast } from "./components/Toast";
import { useNavigate } from "react-router-dom";




export default function Partners() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const {
    isLoggedIn,
    userId,
    token,
    userType,
    apiUrl,
    partnerCode,
    isLoading,
    setLoader,
    unsetLoader,
  } = useAuth();
  const [searchInput, setSearchInput] = useState("");
  const [searchCategory, setSearchCategory] = useState("null");
  const [searchExpiry, setSearchExpiry] = useState("null");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPartners, setIsPartners] = useState(false);
  const [partners, setPartners] = useState([]);
  const [partnerId, setPartnerId] = useState(undefined)
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const toaster = useToast();

  const columns = [
    {
      field: "operations_zone",
      headerName: "Zona Operativa",
      width: 160,
      editable: true,
    },
    {
      field: "name",
      headerName: "Nome",
      width: 130,
      editable: true,
    },
    {
      field: "lastname",
      headerName: "Cognome",
      width: 130,
      editable: true,
    },
    /* {
      field: "address",
      headerName: "Indirizzo",
      width: 160,
      editable: true,
    },
    {
      field: "zipcode",
      headerName: "CAP",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
    },
    {
      field: "vat",
      headerName: "Partita Iva",
      width: 160,
      editable: true,
    },
    {
      field: "partner_code",
      headerName: "Codice Partner",
      width: 130,
      editable: true,
    },*/
    {
      field: "annual_offers_budget",
      headerName: "Budget Offerte",
      width: 130,
      editable: true,
    },
    {
      field: "annual_orders_budget",
      headerName: "Budget Ordini",
      width: 130,
      editable: true,
    },
    {
      field: "total_offers",
      headerName: "Totale Offerte",
      width: 130,
      editable: true,
    },
    {
      field: "total_orders",
      headerName: "Totale Ordini",
      width: 130,
      editable: true,
    },
    /*{
      field: "email",
      headerName: "Email",
      width: 200,
      editable: true,
    },*/
    /* {
      field: "city",
      headerName: "Citta",
      width: 160,
      editable: true,
    },
   {
      field: "phone",
      headerName: "Telefono",
      width: 160,
      editable: true,
    },*/
    {
      field: "category",
      headerName: "Categoria",
      width: 160,
      editable: true,
    },
    {
      field: "expiry_period",
      headerName: "Durata",
      width: 100,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Azioni",
      width: 320,
      sortable: false,
      renderCell: (params) => (
        <div>
        {params.row.status == 1 ? (
        <>
        
           <Link to={`/partners/edit/${params.row._id}`}>
            <Button
              size="small"
              variant="outlined"
              sx={{ marginRight: "4px", lineHeight: "0px", height: "31px" }} 
            >
              Modifica
            </Button>
          </Link>
          <Link to={`/partners/view/chart/${params.row.partner_code}`}>
            <Button
              size="small"
              variant="outlined"
              sx={{ marginRight: "4px", lineHeight: "0px", height: "31px" }}
            >
              Statistiche
            </Button>
          </Link>
          <Button
              size="small"
              variant="outlined"
              sx={{ marginRight: "4px", lineHeight: "0px", height: "31px" }}
              onClick={() => handlePartners(params.row.partner_code)}
            >
              Migrazione
            </Button>
           
            
        </>
     ) : (
        <Button
          size="small"
          variant="outlined"
          onClick={() => restorePartner(params.row.partner_code)}
          sx={{ lineHeight: "0px", height: "31px" }}
        >
          Riattiva
        </Button>
      )}
      </div>
      ),
    },
  ];


  const fetchData = async () => {
    try {
      setLoader();
      if (isLoggedIn == false) {
        return false;
      }
      let _search_input = searchInput == "" ? "null" : searchInput;
      const response = await API.get(
        `/partners/${_search_input}/${searchCategory}/${searchExpiry}`
      );
      unsetLoader();
      setRows(response.data.data);
      console.log(response.data.data)
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  };

  useEffect(() => {
    

    fetchData();
  }, [
    searchInput,
    searchExpiry,
    searchCategory,
    token,
    isLoggedIn,
    apiUrl,
    partnerCode,
  ]);

  // useEffect(() => {
  //   if (!userType) return;
  //   if (userType == "partner") {
  //     history("/"); // Redirect to the root route
  //   }
  // }, [userType]);

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSearchCategory(event.target.value);
  };

  const handleExpiryChange = (event) => {
    setSearchExpiry(event.target.value);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (isPartners == true) {
      setIsPartners(false);
      setSelectedCheckboxes([]);
    }
  };

  const handlePartners = async (id) => {
    try {
      const selectedRow = rows.find((item) => item.partner_code === id);
      if (selectedRow) {
        setIsPartners(true);
        setPartnerId(id)
        const url = `/migrate_exclusive/${id}`;
        const response = await API.get(url);
        if (response.data.message == "ok") {
          setIsModalOpen(true); // Open the offers modal
          console.log(response.data.data);
          setPartners(response.data.data);
        } else {
          toaster(
            {
              text: `${response.data.data}`,
            },
            { type: "error" }
          );
          //alert(response.data.data)
        }
      }
    } catch (error) {
      console.log(error);
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  };

  const handlePartnersSubmit = async () => {
    try {
      if(selectedCheckboxes.length==0) {
        toaster({text: `Seleziona un partner!`,},{ type: "error" });
        return false;
      }
      
      setLoader();
      const response = await API.post(`/migrate_exclusive`, {
        partner_code_to: selectedCheckboxes[0],
        partner_code_from: partnerId
      });
      setSelectedCheckboxes([]);
      setPartners([]);
      setPartnerId(undefined)
      if (response.data.message == "ok") {
        toaster({text: `${response.data.data}`,},{ type: "success" });
        unsetLoader();
        setIsModalOpen(false);
        fetchData();
      } else {
        unsetLoader();
        toaster({text: `${response.data.data}`,},{ type: "error" });
      }
    } catch (error) {
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
      unsetLoader();
    }
  }; 

  const handleCheckboxChange = (event) => {
    const checkboxValue = event.target.value;
    if (event.target.checked) {
      setSelectedCheckboxes((prevSelected) => [checkboxValue]);
    } else {
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((value) => value !== checkboxValue)
      );
    }
  };

  const handleExportXlsPartners = async () => {
    try {
      setLoader();
      const url = `/export_partners`;
      const response = await API.get(url);
      if (response.data.message == "ok") {
        window.open(`${apiUrl}/downloads/partners.xlsx`);
        //toast.success(response.data.data);
        unsetLoader();
      } else {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "error" }
        );
      }
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  };

  const restorePartner = async (partner_code) => {
    try {
      setLoader();
      const url = `${apiUrl}/partners/restore`;
      const response = await API.post(
        url,
        { partner_code: partner_code },
        { headers: { token: `${token}` } }
      );
      if (response.data.message == "ok") {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "success" }
        );
        setTimeout(() => {fetchData();}, 1000);
      } else {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "error" }
        );
      }
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  };


  return (
    <div>
      {/* {isLoggedIn ? ( */}
      <div>
        {/* <Navbar /> */}
        {/* <Container maxWidth="lg"> */}
        <Box>
          <h1>Elenco Partners Rete Commerciale</h1>
          {/* <div style={{ marginBottom: "10px" }}> */}
          {/* Toolbar with input field and dropdown */}
          {/* <input
                type="text"
                placeholder="Search"
                style={{ marginRight: "10px" }}
              /> */}
              <legend>AGEN: Agente, COMM: Commerciale, DIST: Distributore, ESCO: Esco, INST: Installatore, PROG: Progettista</legend>
              <Box sx={{ marginBottom: '20px' }}></Box>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { mb: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-basic"
              label="Cerca"
              variant="outlined"
              size="small"
              sx={{ mr: 1 }}
              value={searchInput}
              onChange={handleSearchInputChange}
            />
            <FormControl fullWidth>
              <Select
                size="small"
                value={searchCategory}
                onChange={handleCategoryChange}
                sx={{ mr: 1 }}
              >
                <MenuItem value="null">Tutte le Categorie</MenuItem>
                <MenuItem value="Agente">Agente</MenuItem>
                <MenuItem value="Commerciale">Commerciale</MenuItem>
                <MenuItem value="Distributore">Distributore</MenuItem>
                <MenuItem value="Esco">Esco</MenuItem>
                <MenuItem value="Installatore">Installatore</MenuItem>
                <MenuItem value="Progettista">Progettista</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <Select
                size="small"
                value={searchExpiry}
                onChange={handleExpiryChange}
              >
                <MenuItem value="null">Tutt le Durate</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="12">12</MenuItem>
                <MenuItem value="24">24</MenuItem>
              </Select>
            </FormControl>
            &nbsp;
            <Button
              variant="contained"
              color="primary"
              onClick={handleExportXlsPartners}
            >Esporta Excel</Button>
            {/* </Container> */}
          </Box>
          <Box sx={{ width: "100%" }}>
            <DataGrid
             
              rows={rows}
              isCellEditable={() => false}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[15]}
              disableRowSelectionOnClick
              disableSelectionOnClick
              disableColumnMenu
              getRowClassName={(params) => {
                return params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
                //params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
              }}
            />
          </Box>
          {/* </Container> */}
        </Box>
      </div>
      {/* ) : (
        <Signin />
      )} */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              height: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "auto",
            }}
          >

            {isPartners && (
              <div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Seleziona</TableCell>
                        <TableCell>Nome</TableCell>
                        <TableCell>Cognome</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {partners.map((partnerObject, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Checkbox
                              value={partnerObject.partner_code}
                              checked={selectedCheckboxes.includes(
                                partnerObject.partner_code
                              )}
                              onChange={handleCheckboxChange}
                            />
                          </TableCell>
                          <TableCell>{partnerObject.name}</TableCell>
                          <TableCell>{partnerObject.lastname}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box textAlign="center" mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handlePartnersSubmit()}
                  >
                    Salva
                  </Button>
                </Box>
              </div>
            )}

          </Box>
        </Modal>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </div>
  );
}
