import React, { useState, useEffect } from "react";
import {  TextField,
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
Checkbox } from "@mui/material";

import Box from "@mui/material/Box";
import Navbar from "./Navbar";
import Signin from "./Signin";
import Container from "@mui/material/Container";
import { useAuth } from "./AuthContext";
import { useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { toast } from "react-toastify";
import { useToast } from "./components/Toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "./config/API";
import { useNavigate } from "react-router-dom";

export default function ExclusivesEdit() {
  const navigate = useNavigate();
  const { isLoggedIn, apiUrl, partnerCode, token, setLoader, unsetLoader } =
    useAuth();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    _id: "",
    company: "",
    address: "",
    city: "",
    vat: "",
    email: "",
    province: "",
    expiry_date: "",
    new_date: "",
    partner_code: "",
    is_all_group: "",
    vat_special: 0
  });
  const toaster = useToast();

  useEffect(() => {
    const fetchPartnerData = async () => {
      try {
        setLoader();
        const response = await API.get(`/exclusives/${id}`);
        //console.log(response.data.data)
        console.log(response.data.data["expiry_date"]);
        response.data.data["expiry_date"] = moment(
          response.data.data["expiry_date"]
        ).format("DD/MM/YYYY"); //formatDate(response.data.data['expiry_date'])
        console.log(response.data.data);
        response.data.data["expiry_date_old"] =
          response.data.data["expiry_date"];
        unsetLoader();
        delete response.data.data.group;
        delete response.data.data.vat_special;
        setFormData(response.data.data); // Populate form fields with fetched data
      } catch (error) {
        unsetLoader();
        console.error("Error fetching partner data:", error);
      }
    };
    fetchPartnerData();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData)
    //return false;
    // Check if any field is empty, null, or undefined
    for (const key in formData) {
      console.log(key, formData[key])
      if (key != "group" && key != "group_address") {
        if (!formData[key]) {
          toaster(
            {
              text: `${key} non puo' essere vuoto!`,
            },
            { type: "error" }
          );
          return false; // Exit the function if any field is empty
        }
      }
    }
    
    //delete formData['is_all_group']
    try {
      setLoader();
      const response = await API.put(`${apiUrl}/exclusives`, formData);
      if (response.data.message == "ok") {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "success" }
        );
        if(formData['partner_code'].includes('SEDE')) {
          setTimeout(() => {navigate("/exclusives/admins");}, 1000);
        } else {
          setTimeout(() => {navigate("/exclusives");}, 1000);
        }
      } else {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "error" }
        );
        //alert(response.data.data)
      }
    } catch (error) {
      unsetLoader();
      toaster(
        {
          text: `Error: ${response.data.data}`,
        },
        { type: "error" }
      ); // Handle error
    }
    
  };

  // Handler for input changes
  const handleChange = (event) => {
    return false;
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleExpiryChange = (date) => {
    if (date) {
      const formattedDate = moment(date["$d"]).format("YYYY-MM-DD");
      setFormData((prevData) => ({
        ...prevData,
        new_date: formattedDate,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        new_date: "", // Reset the expiry_date if date is not provided
      }));
    }
  };

  const formatDate = (_date) => {
    //console.log(_date)
    let d = moment(_date).format("DD/MM/YYYY");
    //console.log(d)
  };

  const delete_item = async (scope, id) => {
    //if(confirm("Sei sicuro di voler cancellare l'offerta?")) {
      try {
        setLoader();
        const response = await API.get(`${apiUrl}/delete_item/${scope}/${id}`);
        console.log(response.data);
        if (response.data.message == "ok") {
          toaster(
            {
              text: `${response.data.data}`,
            },
            { type: "success" }
          );
          if(formData['partner_code'].includes('SEDE')) {
            setTimeout(() => {navigate("/exclusives/admins");}, 1000);
          } else {
            setTimeout(() => {navigate("/exclusives");}, 1000);
          }
        } else if (response.data.message == "error") {
          toaster(
            {
              text: `${response.data.data}`,
            },
            { type: "error" }
          );
        }
        unsetLoader();
        
        
       
      } catch (error) {
        unsetLoader();
        console.error("Error:", error);
        toaster({text: `Error fetching data: ${error}`,},{ type: "error" });
      }
   //}
  };

  if (!isLoggedIn) return <Signin />;

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {" "}
        <div style={{ width: "100%", height: "100%" }}>
          <h1>Edit Affidamento {formData.company}</h1>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  readOnly={true}
                  label="Azienda"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  readOnly={true}
                  label="Partita Iva"
                  name="vat"
                  value={formData.vat}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  readOnly={true}
                  label="Indirizzo"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  readOnly={true}
                  label="Citta"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  readOnly={true}
                  label="Provincia"
                  name="province"
                  value={formData.province}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  readOnly={true}
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  readOnly={true}
                  label="Data di Scadenza"
                  name="expiry_date_old"
                  value={formData.expiry_date}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  fullWidth
                  label="Nuova Data Scadenza"
                  name="expiry_date"
                  format="DD/MM/YYYY"
                  value={formatDate(formData.new_date)}
                  onChange={handleExpiryChange}
                />
              </Grid>
              {formData.vat_special==1 && formData.is_all_group == 1 &&(
              <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_all_group"
                    color="primary"
                    checked={formData.is_all_group}
                    inputProps={{ readOnly: true }}
                  />
                }
                label="Esclusiva su tutto il Gruppo" 
              />
            </Grid>
              )}

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ backgroundColor: "#050947" }}
                >
                  Salva
                </Button>
                &nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  style={{ backgroundColor: "#050947" }}
                  onClick={() => {
                    if (window.confirm("Sei sicuro di voler cancellare l'affidamento?")) {
                      delete_item("exclusives",formData['_id']);
                    }
                  }}
                >
                  Cancella
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Box>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </div>
  );
}
