import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { TextField, Button, Grid } from "@mui/material";
import Signin from "./Signin";
import { useAuth } from "./AuthContext";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useToast } from "./components/Toast";
import API from "./config/API";
import { useNavigate } from "react-router-dom";

export default function OrdersCreate() {
  const navigate = useNavigate();
  const {
    isLoggedIn,
    userId,
    token,
    userType,
    partnerCode,
    apiUrl,
    isLoading,
    setLoader,
    unsetLoader,
  } = useAuth();
  const [formData, setFormData] = useState({
    offer_id: "",
    offer_global_id: "",
    company: "",
    vat: "",
    product: "",
    amount: "",
    cost: "",
    margin: "",
    partner_code: "",
    exclusive_id: ""
  });
  const { id } = useParams();
  const { partner_code } = useParams();
  const [offerAmount, setOfferAmount] = useState(0);
  const [target, setTarget] = useState(undefined);
  const [companyReadOnly, setCompanyReadOnly] = useState(false);
  const toaster = useToast();

  useEffect(() => {
    const calculateMargin = () => {
      let { amount, cost } = formData;
      amount = amount.replace(".","").replace(",",".")
      cost = cost.replace(".","").replace(",",".")
      if (
        !amount ||
        parseFloat(amount) === 0 ||
        !cost ||
        parseFloat(cost) === 0
      ) {
        setFormData((prevData) => ({
          ...prevData,
          margin: 0,
        }));
      } else {
        const margin =  (parseFloat(amount) - parseFloat(cost)) / (parseFloat(cost) / 100);
        setFormData((prevData) => ({
          ...prevData,
          margin: isNaN(margin) ? undefined : margin.toFixed(2).replace(".",","),
        }));
      }
    };


    calculateMargin();
  }, [formData.amount, formData.cost]);

  useEffect(() => {
    if (id !== "diretto") {
      fetchData();
    } else {
      setTarget("Sede");
    }
  }, [id]); // Trigger the effect only when `id` changes

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      setLoader();
      const response = await API.get(`${apiUrl}/offers/${id}`);
      console.log(response.data);
      setFormData((prevFormData) => ({
        ...prevFormData,
        company: response.data.data.company,
        vat: response.data.data.vat,
        product: response.data.data.product,
        amount: response.data.data.amount,
        cost: response.data.data.cost,
        margin: response.data.data.margin,
        offer_id: response.data.data.offer_id,
        offer_global_id: response.data.data.offer_global_id,
        partner_code: response.data.data.partner_code,
        exclusive_id: response.data.data.exclusive_id,
      }));
      setTarget(
        ` per Offerta ${response.data.data.offer_id} - ${response.data.data.company}`
      );
      //setTarget(response.data.data.company)
      setCompanyReadOnly(true);
      unsetLoader();
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  };

  function convertEuropeanToNumeric(stringValue) {
    const [integerPart, decimalPart] = stringValue.split(',');
    const cleanIntegerPart = integerPart.replace(/\./g, '');
    const cleanDecimalPart = decimalPart ? decimalPart.replace(/\./g, '') : '';
    const numericValue = cleanDecimalPart ? `${cleanIntegerPart}.${cleanDecimalPart}` : cleanIntegerPart
    return numericValue;
  }

  // Handler for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    for (const key in formData) {
      if (!formData[key]) {
        toaster(
          {
            text: `${key} non puo' essere vuoto!`,
          },
          { type: "error" }
        );
        return;
      }
      if(key == 'amount') {
        formData['amount'] = convertEuropeanToNumeric(formData['amount'])//.replace(".","").replace(",",".")
      }
      if(key == 'cost') {
        formData['cost'] = convertEuropeanToNumeric(formData['cost'])//.replace(".","").replace(",",".")
      }
      if(key == 'margin') {
        formData['margin'] = convertEuropeanToNumeric(formData['margin'])//.replace(".","").replace(",",".")
      }
    }
    try {
      setLoader();
      let payload = formData;
      console.log(payload);
      const response = await API.post(`${apiUrl}/orders`, payload);
      if (response.data.message == "ok") {
        unsetLoader();
        toaster({text: `${response.data.data}`,},{ type: "success" });

        if(formData["partner_code"].includes('SEDE')) {
          setTimeout(() => {navigate("/orders/admins/all");}, 1000);
        } else {
          setTimeout(() => {navigate("/orders/all");}, 1000);
        }
      
      
      } else {
        unsetLoader();
        toaster({text: `${response.data.data}`,},{ type: "error" });
        //alert(response.data.data)
      }
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
    
  };

  // Handler for input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  if (!isLoggedIn) return <Signin />;

  return (
    <div style={{ height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <h1>Nuovo Ordine {target}</h1>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Azienda"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  inputProps={{
                    readOnly: companyReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Partiva Iva"
                  name="vat"
                  value={formData.vat}
                  onChange={handleChange}
                  inputProps={{
                    readOnly: companyReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Prodotto"
                  name="product"
                  value={formData.product}
                  onChange={handleChange}
                  inputProps={{
                    readOnly: companyReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Importo"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Costo"
                  name="cost"
                  value={formData.cost}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Margine"
                  name="margin"
                  value={formData.margin}
                  onChange={handleChange}
                  inputProps={{
                    readOnly: companyReadOnly,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ backgroundColor: "#050947" }}
                >
                  Salva
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Box>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </div>
  );
}
