import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Signin from "./Signin"; 
import { useAuth } from "./AuthContext";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "./config/API";
import { useToast } from "./components/Toast";
import { useNavigate } from "react-router-dom";

export default function PartnersCreate() {
  const navigate = useNavigate();
  const [partners, setPartners] = useState([]);
  const [emailBgColor, setEmailBgColor] = useState("red");
  const {
    isLoggedIn,
    userId,
    token,
    userType,
    partnerCode,
    apiUrl,
    isLoading,
    setLoader,
    unsetLoader,
  } = useAuth();
  const [formData, setFormData] = useState({
    operations_zone: "",
    annual_offers_budget: "",
    annual_orders_budget: "",
    name: "",
    lastname: "",
    email: "",
    address: "",
    zipcode: "",
    city: "",
    province: "",
    vat: "",
    phone: "",
    category: "",
    expiry_period: "",
    expiry_period_custom: "",
    subpartners : []
  });
  const toaster = useToast();


  const partners_vat = async (value) => {
    setLoader(); 
    const response = await API.get(`/partners_vat/${value}`);
    console.log(response)
    if (response.data.message == "ok") {
      setFormData(response.data.obj)
    }
    unsetLoader();
  }

  function convertEuropeanToNumeric(stringValue) {
    const [integerPart, decimalPart] = stringValue.split(',');
    const cleanIntegerPart = integerPart.replace(/\./g, '');
    const cleanDecimalPart = decimalPart ? decimalPart.replace(/\./g, '') : '';
    const numericValue = cleanDecimalPart ? `${cleanIntegerPart}.${cleanDecimalPart}` : cleanIntegerPart
    return numericValue;
  }

  const fetchData = async () => {
    try {
      setLoader();
      const url = `/migrate_exclusive/${userId}`;
        const response = await API.get(url);
        if (response.data.message == "ok") {
          console.log(response.data.data);
          setPartners(response.data.data);
        } else {
          toaster(
            {
              text: `${response.data.data}`,
            },
            { type: "error" }
          );
          //alert(response.data.data)
        }
        unsetLoader();
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  };

  useEffect(() => {
    

    fetchData();
  }, [token]);

  // Handler for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      for (const key in formData) {
        if(key != 'expiry_period_custom') {
        if (!formData[key]) {
          toaster(
            {
              text: `${key} non puo' essere vuoto!`,
            },
            { type: "error" }
          );
          return false;
        }
      }
        if(key == 'annual_offers_budget') {
          formData['annual_offers_budget'] = convertEuropeanToNumeric(formData['annual_offers_budget']) //.replace(".","").replace(",",".")
        }
        if(key == 'annual_orders_budget') {
          formData['annual_orders_budget'] = convertEuropeanToNumeric(formData['annual_orders_budget']) //.replace(".","").replace(",",".")
        }
        if(key == 'expiry_period_custom' && (isNaN(formData[key]) || parseInt(formData[key]) <=0 )) {
          toaster(
            {
              text: `La scadenza libera deve essere un numero superiore a 0!`,
            },
            { type: "error" }
          );
          return false;
        }
      }
      setLoader();
      console.log(formData);
      //return false;
      const response = await API.post(`/partners`, formData);
      console.log(response.data); // Handle success
      if (response.data.message == "ok") {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "success" }
        );
        setTimeout(() => {navigate("/partners");}, 1000);
      } else {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "error" }
        );
      }
      
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
    
  };


  const handleChangePartners = (event) => {
    return '1'
  }

  const handleChange = (event) => {
    
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name=='vat' && value.length==11) {
      partners_vat(value)
    }
    if (name == "email" && value!="") {
      setEmailBgColor("#ffffff");
    } else if(name == "email" && value == "") {
      setEmailBgColor("red");
    }
  };

  const handleNumericChange = (e) => {
    const { name, value } = e.target;
    // Check if the entered value is a valid number
    if (!isNaN(value)) {
      // Update state with formatted number
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: formatNumber(value),
      }));
    }
  };

  function formatNumber(value) {
    //const cleanValue = value.replace(/[^\d.]/g, '');
    const cleanValue = value
    const [integerPart, decimalPart] = cleanValue.split('.');
    console.log(integerPart)
    console.log(decimalPart)
    // Format integer part with commas
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // Combine integer part with decimal part (if any)
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  }

  if (!isLoggedIn) return <Signin />;

  return (
    <div style={{ height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <h1>Nuovo Partner Rete Commerciale</h1>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Partita IVA" 
                  name="vat"
                  value={formData.vat}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nome"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Cognome"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Indirizzo"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Cap"
                  name="zipcode"
                  value={formData.zipcode}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Città"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Provincia"
                  name="province"
                  value={formData.province}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  inputProps={{
                    readOnly: false, //step1Completed && formData.vat_special == 0 && step1Error5==false,
                    sx: {
                      backgroundColor: emailBgColor,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Telefono"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Zona Operativa"
                  name="operations_zone"
                  value={formData.operations_zone}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Budget Annuale Offerte"
                  name="annual_offers_budget"
                  value={formData.annual_offers_budget}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Budget Annuale Ordini"
                  name="annual_orders_budget"
                  value={formData.annual_orders_budget}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Limite Numero Affidamenti"
                  name="exclusives_limit_number"
                  value={formData.exclusives_limit_number}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="category-label">Categoria</InputLabel>
                  <Select
                    labelId="category-label"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                  >
                    <MenuItem value="Commerciale">Commerciale</MenuItem>
                    <MenuItem value="Distributore">Distributore</MenuItem>
                    <MenuItem value="Esco">Esco</MenuItem>
                    <MenuItem value="Installatore">Installatore</MenuItem>
                    <MenuItem value="Progettista">Progettista</MenuItem>
                    <MenuItem value="Agente">Agente</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="expiry_period-label">Scadenza Affidamenti Clienti Partner Commerciale</InputLabel>
                  <Select
                    labelId="expiry_period-label"
                    name="expiry_period"
                    value={formData.expiry_period}
                    onChange={handleChange}
                  >
                    <MenuItem value={"6"}>6 Mesi</MenuItem>
                    <MenuItem value={"12"}>12 Mesi</MenuItem>
                    <MenuItem value={"24"}>24 Mesi</MenuItem>
                    <MenuItem value={"custom"}>Libero</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {formData.expiry_period === 'custom' && (
              <Grid item xs={12}>
              <TextField
                value={formData.expiry_period_custom}
                onChange={handleChange}
                fullWidth
                label="Scadenza Affidamenti Clienti Partner Commerciale Libero"
                name="expiry_period_custom"
              />
              </Grid>
              )}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="partners">Responsabile coordinamento commerciale di:</InputLabel>
                  <Select
                    labelId="subpartners"
                    name="subpartners"
                    value={formData.subpartners || []}
                    onChange={handleChange}
                    multiple
                  >
                    {partners.map(partner => (
                    <MenuItem key={partner.partner_code} value={partner.partner_code}>
                      {partner.name} {partner.lastname}
                    </MenuItem>
                  ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ backgroundColor: "#050947" }}
                >
                  Salva
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Box>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </div>
  );
}
