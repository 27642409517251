import Box from "@mui/material/Box";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  ReferenceLine,
} from "recharts";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LOCALSTORAGE_KEY_TOKEN, useAuth } from "./AuthContext";
import { useEffect, useState } from "react";
import API from "./config/API";
import Speedometer from "react-d3-speedometer";
import { useToast } from "./components/Toast";

export default function Home() {
  const {
    isLoggedIn,
    userId,
    token,
    userType,
    partnerCode,
    apiUrl,
    isLoading,
    setLoader,
    unsetLoader
  } = useAuth();
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState(null);
  const [lineChartData, setLineChartData] = useState([]);
  const [totalOffersAmount, setTotalOffersAmount] = useState(0);
  const [totalOrdersAmount, setTotalOrdersAmount] = useState(0);
  const [totalBudgetOffersAmount, setTotalBudgetOffersAmount] = useState(0);
  const [totalBudgetOrdersAmount, setTotalBudgetOrdersAmount] = useState(0);
  const [lineChartDataReference, setLineChartDataReference] = useState(0);
  const [additionalTextOffers, setAdditionalTextOffers] = useState("");
  const [additionalTextOrders, setAdditionalTextOrders] = useState("");
  const [partnerName, setPartnerName] = useState(undefined)
  const toaster = useToast();

  useEffect(() => {

   
    fetchData();
  }, [isLoggedIn]); 

  const fetchData = async () => {
    //const localStorageData = localStorage.getItem(LOCALSTORAGE_KEY_TOKEN);
    //const userData = JSON.parse(localStorageData);
    //if (!userData) return;
    try {
      setLoader();
      if (userType == "admin") {
        const response = await API.get(`/dashboard_admin`);
        unsetLoader();
        setData(response.data.data);
        const response2 = await API.get(`/dashboard_titles`);
        console.log(response2.data.data);
        setTotalOffersAmount(response2.data.data.total_offers_value);
        setTotalOrdersAmount(response2.data.data.total_orders_value);
        setTotalBudgetOffersAmount(response2.data.data.total_budget_offers_value)
        setTotalBudgetOrdersAmount(response2.data.data.total_budget_orders_value)

        const response3 = await API.get(`/dashboard_offers_by_month`);
        const randomLineChartData = Array.from(
          { length: 12 },
          (_, index) => ({
            name: index + 1, // Month number
            value: 2, //Math.floor(Math.random() * 1000)+10, // Random value
          })
        );
        console.log(randomLineChartData);
        console.log(response3.data.data);
        console.log(response3.data.data);
        setLineChartData(response3.data.data);
      
      } else if (userType == "partner") {
        
        //const info_partner = await API.get(`/partners/${userId}`)
        
      

        const response = await API.get(`/dashboard/partner/${partnerCode}`);
        const response2 = await API.get(`/dashboard_partner_offers/${partnerCode}`);
        unsetLoader();
        console.log(response.data.data);
        console.log(response2.data.data);
        setFormData(response.data.data);
        setLineChartData(response2.data.data.data);
        setLineChartDataReference(parseFloat(response2.data.data.reference));
        setPartnerName(response.data.data.name)
        calcOffers(response.data.data.offers_value,response.data.data.annual_offers_budget)
        calcOrders(response.data.data.orders_value,response.data.data.annual_orders_budget)
        unsetLoader();
      }
    } catch (error) {
      unsetLoader();
      toaster({text: `Error fetching data: ${error}`,},{ type: "error" });
    }
  };

  const calcOffers = async (current, budget) => {
    let additionalTextOffers = "";
    
    if (current < budget * 0.5) {
      additionalTextOffers = "Grazie per del tuo contributo, prosegui nel raggiungere i tuoi obiettivi";
    } else if (current >= budget * 0.5 && current < budget * 0.8) {
      additionalTextOffers = "Stai ottendendo degli ottimi risultati, aumenta l'azione per raggiungere il budget";
    } else if (current >= budget * 0.8 && current < budget) {
      additionalTextOffers = "Ormai sei vicino al budget! Dai e' l'ultimo sforzo e brinderemo assieme!";
    } else if (current >= budget) {
      additionalTextOffers = "Complimenti! Hai raggiunto il budget!";
    }
  
    setAdditionalTextOffers(additionalTextOffers);
  };
  

  const calcOrders = async (current, budget) => {
    let additionalTextOrders = "";
    
    if (current < budget * 0.5) {
      additionalTextOrders = "Grazie per del tuo contributo, prosegui nel raggiungere i tuoi obiettivi";
    } else if (current >= budget * 0.5 && current < budget * 0.8) {
      additionalTextOrders = "Stai ottendendo degli ottimi risultati, aumenta l'azione per raggiungere il budget";
    } else if (current >= budget * 0.8 && current < budget) {
      additionalTextOrders = "Ormai sei vicino al budget! Dai e' l'ultimo sforzo e brinderemo assieme!";
    } else if (current >= budget) {
      additionalTextOrders = "Complimenti! Hai raggiunto il budget!";
    }
  
    setAdditionalTextOrders(additionalTextOrders);
  };

  if (!isLoggedIn) return <Signin />;

  return (
    <div>
    {userType === 'admin' && (

      <Box textAlign="center">

        <Box>
          <p style={{ fontSize: "36px" }}>
            Totale Valore Offerte: {totalOffersAmount} -
            Totale Valore Ordini: {totalOrdersAmount}
          </p>
          <p style={{ fontSize: "36px" }}>
            Totale Budget Offerte: {totalBudgetOffersAmount} -
            Totale Budget Ordini: {totalBudgetOrdersAmount}
    </p>
        </Box>

        <Box>
          <BarChart
            width={1600}
            height={600}
            data={data}
            margin={{
              top: 80,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend
              formatter={(value, entry, index) => {
                if (value === "affidamenti") return "Affidamenti";
                if (value === "offerte") return "Offerte";
                return value;
              }}
            />
            <Bar
              dataKey="affidamenti"
              fill="#8884d8"
              activeBar={<Rectangle fill="#8884d8" stroke="#8884d8" />}
            />
            <Bar
              dataKey="offerte"
              fill="#82ca9d"
              activeBar={<Rectangle fill="#82ca9d" stroke="#82ca9d" />}
            />
            <text x={800} y={40} textAnchor="middle" fontSize={20}>
              {" "}
              {/*fontWeight="bold"*/}
              Distribuzione Affidamenti/Offerte per Categoria
              Commerciale
            </text>
          </BarChart>
        </Box>
        <Box sx={{ marginBottom: "50px" }}>&nbsp;</Box>
        <Box>
          <LineChart
            width={1600}
            height={600}
            data={lineChartData}
            margin={{
              top: 80,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis dataKey="value" />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="value"
              stroke="#8884d8"
              strokeWidth={2}
            />
            <text x={800} y={40} textAnchor="middle" fontSize={20}>
            Totale Valore Offerte Mensili
            </text>
          </LineChart>
        </Box>
      </Box>

  )}

{userType === 'partner' && (
  <Box textAlign="center">
    <Box>
      <p style={{ fontSize: "36px" }}>
        Ciao {partnerName}, questa e' la tua situazione operativa commerciale
      </p>
    </Box>
    <Box sx={{ marginBottom: "20px" }}>&nbsp;</Box>
    <Grid container spacing={40} justifyContent="center">
      <Grid item>
        <Box textAlign="center">
          <h2>Saturazione Budget Offerte</h2>
          {formData && (
            <>
              <Speedometer
                value={Math.min(parseFloat(formData.offers_value), parseFloat(formData.annual_offers_budget))}
                minValue={0}
                maxValue={parseFloat(formData.annual_offers_budget)}
                needleColor="black"
                startColor="red"
                segments={5}
                endColor="green"
                textColor="black"
                width={600}
                height={400}
                showValue={false} 
              />
              <p style={{ fontSize: "18px", fontWeight: "bold"}}>{additionalTextOffers}</p>
            </>
          )}
        </Box>
      </Grid>
      <Grid item>
        <Box textAlign="center">
          <h2>Saturazione Budget Ordini</h2>
          {formData && (
            <>
              <Speedometer
                value={Math.min(parseFloat(formData.orders_value), parseFloat(formData.annual_orders_budget))}
                minValue={0}
                maxValue={parseFloat(formData.annual_orders_budget)}
                needleColor="black"
                startColor="red"
                segments={5}
                endColor="green"
                textColor="black"
                width={600}
                height={400}
              />
               <p style={{ fontSize: "18px", fontWeight: "bold" }}>{additionalTextOrders}</p>
            </>
          )}
        </Box>
      </Grid>
    </Grid>

    <Box sx={{ marginBottom: "50px" }}>&nbsp;</Box>
      <Box>
        <LineChart
          width={1600}
          height={600}
          data={lineChartData}
          margin={{
            top: 80,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis dataKey="value" domain={[0, lineChartDataReference]} />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            strokeWidth={2}
          />
          <ReferenceLine
            y={lineChartDataReference}
            label="Obiettivo"
            stroke="red"
            strokeDasharray="3 3"
          />
          <text x={800} y={40} textAnchor="middle" fontSize={20}>
            Saturazione Budget Offerte per Mese
          </text>
        </LineChart>
      </Box>

  </Box>
)}


    
    </div>
  );
}
