import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Box from "@mui/material/Box";
import Signin from "./Signin";
import { useAuth } from "./AuthContext";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "./config/API";
import { useToast } from "./components/Toast";
import { useNavigate } from "react-router-dom";

export default function PartnersEdit() {
  const navigate = useNavigate();
  const {
    isLoggedIn,
    userId,
    token,
    userType,
    partnerCode,
    apiUrl,
    isLoading,
    setLoader,
    unsetLoader,
  } = useAuth();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    _id: "",
    operations_zone: "",
    annual_offers_budget: "",
    annual_orders_budget: "",
    exclusives_limit_number: "",
    name: "",
    lastname: "",
    address: "",
    zipcode: "",
    city: "",
    vat: "",
    phone: "",
    email: "",
    category: "",
    expiry_period: "",
  });
  const toaster = useToast();

  useEffect(() => {
    const fetchPartnerData = async () => {
      try {
        setLoader();
        const response = await API.get(`/admins/${id}`);
        unsetLoader();
        setFormData(response.data.data); // Populate form fields with fetched data
      } catch (error) {
        unsetLoader();
        console.error("Error fetching partner data:", error);
      }
    };
    fetchPartnerData();
  }, [id]);

  const fieldOrder = [
    "operations_zone",
    "name",
    "lastname",
    "vat",
    "email",
    "address",
    "zipcode",
    "city",
    "phone",
    "annual_offers_budget",
    "annual_orders_budget",
    "exclusives_limit_number",
    "category",
    "expiry_period",
  ];

  const fieldLabels = {
    operations_zone: "Zona Operativa",
    name: "Nome",
    lastname: "Cognome",
    address: "Indirizzo",
    zipcode: "CAP",
    city: "Città",
    vat: "Partita IVA",
    phone: "Telefono",
    email: "Email",
    category: "Categoria",
    expiry_period: "Scadenza",
    annual_offers_budget: "Budget Annuale Offerte",
    annual_orders_budget: "Budget Annuale Ordini",
    exclusives_limit_number: "Limite Numero Affidamenti",
  };

  // Handler for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Check if any field is empty, null, or undefined
    console.log(formData)
    //return false;
    for (const key in formData) {
        if (key != "expiry_date") {
      if (!formData[key]) {
        toaster(
          {
            text: `${key} non puo' essere vuoto!`,
          },
          { type: "error" }
        );
        return false;
      }
      if(key == 'annual_offers_budget') {
        formData['annual_offers_budget'] = formData['annual_offers_budget'].replace(".","").replace(",",".")
      }
      if(key == 'annual_orders_budget') {
        formData['annual_orders_budget'] = formData['annual_orders_budget'].replace(".","").replace(",",".")
      }
    }
    }
    try {
      setLoader();
      const response = await API.put(`/admins`, formData);
      if (response.data.message == "ok") {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "success" }
        );
        setTimeout(() => {navigate("/admins");}, 1000);
      } else {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "error" }
        );
        //alert(response.data.data)
      }
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
    
  };

  // Handler for input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  if (!isLoggedIn) return <Signin />;

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <h1>
            Edit Admin {formData.name} {formData.lastname}
          </h1>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {fieldOrder.map((key) => (
                <Grid item xs={12} key={key}>
                  {key === "expiry_period" ? (
                    <FormControl fullWidth>
                      <InputLabel id="expiry_period-label">Scadenza Affidamenti Clienti Partner Commerciale</InputLabel>
                      <Select
                        labelId="expiry-period-label"
                        name="expiry_period"
                        value={formData.expiry_period}
                        onChange={handleChange}
                      >
                        <MenuItem value="6">6 Mesi</MenuItem>
                        <MenuItem value="12">12 Mesi</MenuItem>
                        <MenuItem value="24">24 Mesi</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <TextField
                      fullWidth
                      label={
                        fieldLabels[key].charAt(0).toUpperCase() +
                        fieldLabels[key].slice(1)
                      } // Capitalize first letter
                      name={key}
                      value={formData[key]}
                      onChange={handleChange}
                    />
                  )}
                </Grid>
              ))}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ backgroundColor: "#050947" }}
                >
                  Salva
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Box>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </div>
  );
}
