import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Signin from "./Signin";
import { useAuth } from "./AuthContext";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "./config/API";
import { useToast } from "./components/Toast";
import { useNavigate } from "react-router-dom";

export default function ExclusivesCreate() {
  const { isLoggedIn, apiUrl, partnerCode, token, setLoader, unsetLoader } =
    useAuth();
  const [formData, setFormData] = useState({
    vat: "",
    company: "",
    group: "",
    group_address: "",
    address: "",
    zipcode: "",
    city: "",
    province: "",
    email: "",
    vat_special: "",
    is_all_group: 0,
  });
  const toaster = useToast();
  const navigate = useNavigate();
  const [step1Completed, setStep1Completed] = useState(false);
  const [step2Completed, setStep2Completed] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("Verifica");
  const [step1Success, setStep1Success] = useState(false);
  const [step1Error1, setStep1Error1] = useState(false);
  const [step1Error2, setStep1Error2] = useState(false);
  const [step1Error3, setStep1Error3] = useState(false);
  const [step1Error4, setStep1Error4] = useState(false);
  const [step1Error5, setStep1Error5] = useState(false);
  const [companyBgColor, setCompanyBgColor] = useState("#ffffff");
  const [addressBgColor, setAddressBgColor] = useState("#ffffff");
  const [zipcodeBgColor, setZipcodeBgColor] = useState("#ffffff");
  const [cityBgColor, setCityBgColor] = useState("#ffffff");
  const [provinceBgColor, setProvinceBgColor] = useState("#ffffff");
  const [emailBgColor, setEmailBgColor] = useState("#ffffff");
  const [companyLabel, setCompanyLabel] = useState("");
  const [target, setTarget] = useState("");
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(partnerCode);
  const [selectedPartnerName, setSelectedPartnerName] = useState(null);
  const [isVatSpecial, setIsVatSpecial] = useState(0);

  useEffect(() => {
    console.log("V2");
    const fetchPartnersData = async () => {
      try {
        setLoader();
        const response = await API.get(`/partners/null/null/null`);
        console.log(response.data.data);
        setPartners(response.data.data);
        unsetLoader();
      } catch (error) {
        unsetLoader();
        console.error("Error fetching partner data:", error);
      }
    };
    fetchPartnersData();
    if (partnerCode.includes("SEDE")) {
      setSelectedPartnerName("per Sede");
    }
  }, []);

  // Handler for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setStep1Error1(false);
    setStep1Error2(false);
    setStep1Error3(false);
    setStep1Error4(false);
    setStep1Error5(false);
    setStep1Success(false);

    console.log(formData);

    //return false;
    try {
      setLoader();
      if (step1Completed == false) {
        if (formData.vat == "") {
          return false;
        }
        const response = await API.post(`/exclusives/step1`, {
          partner_code: partnerCode,
          vat: formData.vat,
        });
        console.log(response.data);
        console.log(response.data.vat_special);
        console.log(response.data.error_type);
        if (response.data.message == "ok") {
          setStep1Completed(true);
          setButtonLabel("Salva");
          /* toaster(
            {
              text: `${response.data.data}`,
            },
            { type: "success" }
          );*/
          //toast.success(response.data.data)

          if (response.data.vat_special == 1) {
            setStep1Success(true);
            setCompanyLabel("Azienda del Gruppo");
            setIsVatSpecial(1);
            setFormData((prevData) => ({
              ...prevData,
              vat_special: response.data.vat_special,
              group: response.data.group,
              group_address: response.data.group_address,
            }));
            /*setFormData((prevData) => ({
                ...prevData,
                vat_special: response.data.vat_special
              }));*/
            setCompanyBgColor("#90EE90");
            setAddressBgColor("#90EE90");
            setZipcodeBgColor("#90EE90");
            setProvinceBgColor("#90EE90");
            setCityBgColor("#90EE90");
            setEmailBgColor("#90EE90");
          } else if (response.data.vat_special == 0) {
            setStep1Success(true);
            setCompanyLabel("Azienda");
            console.log(response.data.data);
            setFormData((prevData) => ({
              ...prevData,
              ...response.data.obj,
            }));
            setFormData((prevData) => ({
              ...prevData,
              vat_special: response.data.vat_special,
            }));
          }
          console.log(formData);
          if (response.data.error_type == 5) {
            setStep1Success(true);
            setStep1Error5(true);
            setCompanyBgColor("#90EE90");
            setAddressBgColor("#90EE90");
            setZipcodeBgColor("#90EE90");
            setProvinceBgColor("#90EE90");
            setCityBgColor("#90EE90");
            setEmailBgColor("#90EE90");
          }
        } else {
          if (response.data.error_type == 1) {
            setStep1Error1(true);
          }
          if (response.data.error_type == 2) {
            setStep1Error2(true);
          }
          if (response.data.error_type == 3) {
            setStep1Error3(true);
          }
          if (response.data.error_type == 4) {
            setStep1Error4(true);
          }

          //toast.error(response.data.data)
          /*toaster(
            {
              text: `${response.data.data}`,
            },
            { type: "error" }
          );*/
        }
      }
      if (step1Completed == true && step2Completed == false) {
        //alert('here');
        //return false;
        // CHECK FIELDS FOR NORMAL COMPANY
        if (isVatSpecial == 0) {
          console.log("NO SPeCIAL");
          console.log(formData);
          for (const key in formData) {
            if (
              key != "group" &&
              key != "group_address" &&
              key != "vat_special"
            ) {
              if (
                formData[key] === undefined ||
                formData[key] === null ||
                formData[key] === ""
              ) {
                toaster(
                  {
                    text: `${key} non puo' essere vuoto!`,
                  },
                  { type: "error" },
                );
                unsetLoader();
                return; // Exit the function if any field is empty
              }
            }
          }
        }
        // CHECK FIELDS FOR GROUP
        if (isVatSpecial == 1) {
          console.log(formData);
          console.log("SPeCIAL");
          for (const key in formData) {
            if (
              formData[key] === undefined ||
              formData[key] === null ||
              formData[key] === ""
            ) {
              toaster(
                {
                  text: `${key} non puo' essere vuoto!`,
                },
                { type: "error" },
              );
              unsetLoader();
              return; // Exit the function if any field is empty
            }
          }
        }
        let payload = formData;
        payload["partner_code"] = partnerCode;
        payload["override_partner"] = selectedPartner;
        if (payload["vat_special"] == "") {
          payload["vat_special"] = 0;
        }
        console.log("step 2 payload");
        console.log(payload);
        //return false;
        const response = await API.post(`/exclusives/step2`, payload);
        console.log(response.data.data);
        if (response.data.message == "ok") {
          setStep2Completed(true); // Set step 1 completion flag to true
          setIsVatSpecial(0);
          toaster(
            {
              text: `${response.data.data}`,
            },
            { type: "success" },
          );

          if (
            partnerCode.includes("SEDE") &&
            payload["override_partner"] == partnerCode
          ) {
            setTimeout(() => {
              navigate("/exclusives/admins");
            }, 1000);
          } else {
            setTimeout(() => {
              navigate("/exclusives");
            }, 1000);
          }
        } else {
          toaster(
            {
              text: `${response.data.data}`,
            },
            { type: "error" },
          );
        }
      }
      unsetLoader();
    } catch (error) {
      unsetLoader();

      console.error("Error:", error);
    }
  };

  // Handler for input changes
  const handleSelectPartnerChange = (event) => {
    const { name, value } = event.target;
    setSelectedPartner(value);
    if (!value.includes("SEDE")) {
      const partner = partners.find((p) => p.partner_code === value);
      setSelectedPartnerName(`per ${partner.name} ${partner.lastname}`);
    } else {
      setSelectedPartnerName(`per Sede`);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name == "company") {
      setCompanyBgColor("#ffffff");
    }
    if (name == "address") {
      setAddressBgColor("#ffffff");
    }
    if (name == "city") {
      setCityBgColor("#ffffff");
    }
    if (name == "zipcode") {
      setZipcodeBgColor("#ffffff");
    }
    if (name == "province") {
      setProvinceBgColor("#ffffff");
    }
    if (name == "email") {
      setEmailBgColor("#ffffff");
    }
    setFormData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const handleCheckboxChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      is_all_group: event.target.checked ? 1 : 0,
    }));
  };

  if (!isLoggedIn) return <Signin />;

  return (
    <div style={{ height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {" "}
        <div style={{ width: "100%", height: "100%" }}>
          <h1>Nuovo Affidamento {selectedPartnerName}</h1>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {formData.vat_special === 1 && step1Success && (
                <Grid item xs={12}>
                  <p
                    style={{
                      backgroundColor: "#90EE90",
                      border: "1px solid #006400",
                      padding: "10px",
                    }}
                  >
                    &nbsp;Questa e' una Partita Iva di gruppo valida. Completa
                    la compilazione specificando i dati della divisione e clicca
                    Salva.{" "}
                  </p>
                </Grid>
              )}
              {formData.vat_special === 0 &&
                step1Success &&
                step1Error5 === false && (
                  <Grid item xs={12}>
                    <p
                      style={{
                        backgroundColor: "#90EE90",
                        border: "1px solid #006400",
                        padding: "10px",
                      }}
                    >
                      &nbsp;La Partiva Iva e' valida e disponibile. Conferma i
                      dati e clicca Salva. NB: nel caso di campi vuoti,
                      compilali a mano.{" "}
                    </p>
                  </Grid>
                )}
              {step1Error1 === true && (
                <Grid item xs={12}>
                  <p
                    style={{
                      backgroundColor: "#FFC0CB",
                      border: "1px solid #8B0000",
                      padding: "10px",
                    }}
                  >
                    &nbsp;Questa Partiva Iva e' gia' assegnata ad un altro
                    partner!
                  </p>
                </Grid>
              )}
              {step1Error3 === true && (
                <Grid item xs={12}>
                  <p
                    style={{
                      backgroundColor: "#FFC0CB",
                      border: "1px solid #8B0000",
                      padding: "10px",
                    }}
                  >
                    &nbsp;Questa Partiva Iva e' gia' assegnata a te! Attendiamo
                    offerte per fare business assieme!
                  </p>
                </Grid>
              )}
              {step1Error2 === true && (
                <Grid item xs={12}>
                  <p
                    style={{
                      backgroundColor: "#FFC0CB",
                      border: "1px solid #8B0000",
                      padding: "10px",
                    }}
                  >
                    &nbsp;Questa Partita Iva non e' valida!
                  </p>
                </Grid>
              )}
              {step1Error4 === true && (
                <Grid item xs={12}>
                  <p
                    style={{
                      backgroundColor: "#FFC0CB",
                      border: "1px solid #8B0000",
                      padding: "10px",
                    }}
                  >
                    &nbsp;Hai raggiunto il numero massimo di affidamenti.
                    Contatta la Sede per alzare il limite!
                  </p>
                </Grid>
              )}
              {step1Error5 === true && (
                <Grid item xs={12}>
                  <p
                    style={{
                      backgroundColor: "#90EE90",
                      border: "1px solid #006400",
                      padding: "10px",
                    }}
                  >
                    &nbsp;La Partiva Iva e' valida e disponibile ma non siamo
                    riusciti a recuperare i dati. Compila tutti i campi e clicca
                    Salva.{" "}
                  </p>
                </Grid>
              )}

              {partnerCode.includes("SEDE") && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="category-label">Partner</InputLabel>
                    <Select
                      labelId="category-label"
                      name="category"
                      value={selectedPartner}
                      onChange={handleSelectPartnerChange}
                    >
                      <MenuItem key="1" value={partnerCode}>
                        Sede
                      </MenuItem>
                      {partners.map((partner) => (
                        <MenuItem
                          key={partner._id}
                          value={partner.partner_code}
                        >
                          {partner.name} {partner.lastname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Partita Iva"
                  name="vat"
                  value={formData.vat}
                  onChange={handleChange}
                  inputProps={{ readOnly: step1Completed }}
                />
              </Grid>

              {step1Completed && (
                <>
                  {formData.vat_special === 1 && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Gruppo"
                        name="group"
                        value={formData.group}
                        onChange={handleChange}
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Azienda"
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                      inputProps={{
                        readOnly:
                          step1Completed &&
                          formData.vat_special === 0 &&
                          step1Error5 == false,
                        sx: {
                          backgroundColor: companyBgColor,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Indirizzo"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      inputProps={{
                        readOnly: false, //step1Completed && formData.vat_special == 0 && step1Error5==false
                        sx: {
                          backgroundColor: addressBgColor,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Cap"
                      name="zipcode"
                      value={formData.zipcode}
                      onChange={handleChange}
                      inputProps={{
                        readOnly: false, //step1Completed && formData.vat_special == 0 && step1Error5==false,
                        sx: {
                          backgroundColor: zipcodeBgColor,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Citta'"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      inputProps={{
                        readOnly: false, //step1Completed && formData.vat_special == 0 && step1Error5==false,
                        sx: {
                          backgroundColor: cityBgColor,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Provincia"
                      name="province"
                      value={formData.province}
                      onChange={handleChange}
                      inputProps={{
                        readOnly: false, //step1Completed && formData.vat_special == 0 && step1Error5==false,
                        sx: {
                          backgroundColor: provinceBgColor,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      inputProps={{
                        readOnly: false, //step1Completed && formData.vat_special == 0 && step1Error5==false,
                        sx: {
                          backgroundColor: emailBgColor,
                        },
                      }}
                    />
                  </Grid>
                  {formData.vat_special == 1 &&
                    partnerCode.includes("SEDE") && (
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="is_all_group"
                              color="primary"
                              checked={formData.is_all_group}
                              onChange={handleCheckboxChange}
                            />
                          }
                          label="Esclusiva su tutto il Gruppo"
                        />
                      </Grid>
                    )}
                </>
              )}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ backgroundColor: "#050947" }}
                >
                  {buttonLabel}
                </Button>
              </Grid>
            </Grid>
          </form>
          {/* </Container> */}
        </div>
      </Box>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </div>
  );
}
