import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LOCALSTORAGE_KEY_TOKEN, useAuth } from "./AuthContext";
import Signin from "./Signin";
import API from "./config/API";
import { useToast } from "./components/Toast";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    field: "offer_global_id",
    headerName: "ID Globale",
    width: 200,
    editable: true,
  },
  {
    field: "offer_id",
    headerName: "ID Offerta",
    width: 200,
    editable: true,
  },
  {
    field: "date_created",
    headerName: "Data",
    width: 200,
    editable: true,
    valueFormatter: (params) => moment(params.value).format("DD/MM/YYYY"),
  },
  {
    field: "company",
    headerName: "Azienda",
    width: 160,
    editable: true,
  },
  {
    field: "product",
    headerName: "Prodotto",
    width: 160,
    editable: true,
  },
  {
    field: "amount",
    headerName: "Valore",
    width: 160,
    valueFormatter: (params) => `${params.value}`,
  },
  {
    field: "partner_name",
    headerName: "Nome Partner",
    width: 160,
    editable: true,
  },
  {
    field: "partner_lastname",
    headerName: "Cognome Partner",
    width: 160,
    editable: true,
  },
  {
    field: "actions",
    headerName: "Azioni",
    width: 230,
    sortable: false,
    renderCell: (params) => (
      <div>
        <Button
          size="small"
          variant="outlined"
          sx={{ marginRight: "4px", lineHeight: "0px", height: "31px" }}
        >
          Modifica
        </Button>
      </div>
    ),
  },
];

export default function OfffersAdmins() {
  const navigate = useNavigate();
  const { who } = useParams();
  const [searchInput, setSearchInput] = useState("");
  const [searchCategory, setSearchCategory] = useState("hq");
  const [rows, setRows] = useState([]);
  const [notes, setNotes] = useState([]);
  const [offers, setOffers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOffers, setIsOffers] = useState(false);
  const [isNotes, setIsNotes] = useState(false);
  const { isLoggedIn, partnerCode, token, apiUrl, setLoader, unsetLoader } = useAuth();
  const [target, setTarget] = useState("")
  const [notAll, setNotAll] = useState(false)
  const toaster = useToast();


  useEffect(() => {
    const fetchData = async () => {
      //const localStorageData = localStorage.getItem(LOCALSTORAGE_KEY_TOKEN);
      //const userData = JSON.parse(localStorageData);
      //const token = userData.token;
      //const partnerCode = userData.partner_code; 
 
      try {
        setLoader();
        if (isLoggedIn == false) {
          return false;
        }
        //const currentSearchExpiry = (searchExpiry === 'null') ? 'null' : moment(searchExpiry["$d"]).format("DD-MM-YYYY");
        let _searchInput = (searchInput === '') ? 'null' : searchInput;
        if(who!='all') {
          _searchInput = who
          setSearchInput(who)
        }
        const url = `/offers/${_searchInput}/${searchCategory}`;
        const response = await API.get(url);
        const response2 = await API.get(`/vat_to_company/${who}`)
        setTarget(response2.data.data)
        
        unsetLoader();
        setRows(response.data.data);

        (who == "all") ? setTarget("") : setTarget(`Affidamento Sede ${response2.data.data}`)

        if(who!="all") {setNotAll(true)}
      } catch (error) {
        unsetLoader();
        toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
      }
    };
    fetchData();
  }, [searchInput, searchCategory]);

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSearchCategory(event.target.value);
  };

  const handleNotes = (id) => {
    setIsOffers(false);
    setIsNotes(true);
    setIsModalOpen(true);
    const selectedRow = rows.find((item) => item._id === id);
    if (selectedRow) {
      setNotes(selectedRow.notes);
    }
  };

  const requestNote = async (eid) => {
    try {
      const url = `/requests`;
      const response = await API.post(url, { exclusive_id: eid });
      if (response.data.message == "ok") {
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "success" }
        );
      } else {
        toaster(
          {
            text: `Error fetching data: ${response.data.data}`,
          },
          { type: "error" }
        );
      }
    } catch (error) {
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  };

  const handleOffers = async (id) => {
    try {
      const selectedRow = rows.find((item) => item._id === id);
      if (selectedRow) {
        console.log(selectedRow);
        setIsNotes(false);
        setIsOffers(true);
        const url = `/offers/${selectedRow["partner_code"]}`;
        const response = await API.get(url);
        if (response.data.message == "ok") {
          setIsModalOpen(true); // Open the offers modal
          console.log(response.data.data);
          setOffers(response.data.data);
        } else {
          toaster(
            {
              text: `Si è verificato un errore: ${response.data.data}`,
            },
            { type: "error" }
          );
          //alert(response.data.data)
        }
      }
    } catch (error) {
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (isNotes == true) {
      setIsNotes(false);
    }
    if (isOffers == true) {
      setIsOffers(false);
    }
  };

  if (!isLoggedIn) return <Signin />;

  // Pass handleNotes function as a prop to the columns array
  const columnsWithActions = columns.map((column) => {
    if (column.field === "actions") {
      return {
        ...column,
        renderCell: (params) => (
          <div>
            <Link to={`/offers/edit/${params.row._id}`}>
              <Button
                size="small"
                variant="outlined"
                sx={{ marginRight: "4px", lineHeight: "0px", height: "31px" }}
              >
                Modifica
              </Button>
              {/* <button>Edit</button> */}
            </Link>
            <Link to={`/orders/create/${params.row._id}`}>
              <Button
                size="small"
                variant="outlined"
                sx={{ marginRight: "4px", lineHeight: "0px", height: "31px" }}
              >
                Crea Ordine
              </Button>
            </Link>
            {/*   <Button
              size="small"
              variant="outlined"
              onClick={() => handleNotes(params.row._id)}
              sx={{ lineHeight: "0px", height: "31px" }}
            >
              Note
            </Button>
            &nbsp;
            <Button
            size="small"
            variant="outlined"
            onClick={() => requestNote(params.row._id)}
            sx={{ lineHeight: "0px", height: "31px" }}
          >
            Richedi Nota
          </Button>
          &nbsp;
          <Button
          size="small"
          variant="outlined"
          onClick={() => handleOffers(params.row._id)}
          sx={{ lineHeight: "0px", height: "31px" }}
        >
          Offerte
        </Button> */}
          </div>
        ),
      };
    }
    return column;
  });

  return (
    <div>
      <Box>
        <h1>Elenco Offerte {target}</h1>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { mb: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            size="small"
            label="Cerca"
            type="search"
            variant="outlined"
            style={{ marginRight: "10px", marginBottom: "10px" }}
            value={searchInput}
            onChange={handleSearchInputChange}
            inputProps={{ readOnly: notAll }}
          />
        </Box>
        <Box>
          <DataGrid
            className="dataGrid"
            rows={rows}
            columns={columnsWithActions} // Use the modified columns array
            initialState={{ pagination: { paginationModel: { pageSize: 15 } } }}
            pageSizeOptions={[15]}
            disableRowSelectionOnClick
            disableSelectionOnClick
            disableColumnMenu
            isCellEditable={() => false}
            getRowClassName={(params) => {
              return params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
              //params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
            }}
          />
        </Box>
      </Box>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </div>
  );
}
