import React, { useEffect, useState } from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Signin from "./Signin";
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Checkbox,
  Grid,
  InputLabel
} from "@mui/material";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useToast } from "./components/Toast";
import API from "./config/API";
import { useNavigate } from "react-router-dom";



export default function Exclusives() {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [searchExpiry, setSearchExpiry] = useState("null");
  const [searchStatus, setSearchStatus] = useState("null");
  const [searchPartner, setSearchPartner] = useState("null");
  const [rows, setRows] = useState([]);
  const [notes, setNotes] = useState([]);
  const [offers, setOffers] = useState([]);
  const [partners, setPartners] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPartners, setIsPartners] = useState(false);
  const [isNotes, setIsNotes] = useState(false);
  const [isExtension, setIsExtension] = useState(false);
  const [isExtensionOffers, setIsExtensionOffers] = useState(false);
  const [isExtensionOrders, setIsExtensionOrders] = useState(false);
  const [isCreateNote, setIsCreateNote] = useState(false);
  const [noteValue, setNoteValue] = useState(undefined);
  const [reasonValue, setReasonValue] = useState(undefined);
  const [extensionPeriodValue, setExtensionPeriodValue] = useState(undefined);
  const [exclusiveId, setExclusiveId] = useState(undefined);
  const [exclusiveVat, setExclusiveVat] = useState(undefined);
  const [exclusivePartnerCode, setExclusivePartnerCode] = useState(undefined);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [target, setTarget] = useState("")
  const {
    isLoggedIn,
    partnerCode,
    token,
    apiUrl,
    userType,
    isLoading,
    setLoader,
    unsetLoader,
  } = useAuth();

  const toaster = useToast();

  window.redirectOffers = async (vat) => {
    navigate(`/offers/${vat}`);
  }

  window.redirectOrders = async (vat) => {
    navigate(`/orders/${vat}`);
  }

  const fetchData = async () => {
    try {
      setLoader();
      if (isLoggedIn == false) {
        return false;
      }
      const currentSearchExpiry =
        searchExpiry === "null"
          ? "null"
          : moment(searchExpiry["$d"]).format("DD-MM-YYYY");
      const _searchInput = searchInput === "" ? "null" : searchInput;
      ///const url =
       // partnerCode !== "SEDE001333" && partnerCode != 'SEDE002333'
         // ? `/exclusives/partner_code/${partnerCode}`
          //: `/exclusives/partners/${_searchInput}/${currentSearchExpiry}/${searchStatus}`;

      const url = !partnerCode.includes("SEDE")
      ? `/exclusives/partner_code/${partnerCode}`
      : `/exclusives/partners/${_searchInput}/${currentSearchExpiry}/${searchStatus}/${searchPartner}`

      if(partnerCode.includes("SEDE")) {
        setTarget("Partners Rete Commerciale")
      }

      const response = await API.get(url);

      setRows(response.data.data);

      // fetch partners
      const url2 = `partners/null/null/null`;
        const response2 = await API.get(url2);
        if (response2.data.message == "ok") {
          console.log(response2.data.data);
          setPartners(response2.data.data);
        } else {
          toaster(
            {
              text: `${response2.data.data}`,
            },
            { type: "error" }
          );
          //alert(response.data.data)
        }
        unsetLoader();
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchInput, searchExpiry, searchStatus, searchPartner, isLoggedIn, partnerCode]);


  const columns = [
    {
      field: "company",
      headerName: "Azienda",
      width: 230,
      editable: true,
    },
    {
      field: "vat",
      headerName: "Partita Iva",
      width: 140,
      editable: true,
    },
    {
      field: "partner_name",
      headerName: "Nome Partner",
      width: 140,
      editable: true,
    },
    {
      field: "partner_lastname",
      headerName: "Cognome Partner",
      width: 140,
      editable: true,
    },
    {
      field: "expiry_date",
      headerName: "Scadenza",
      width: 140,
      editable: true,
    },
    {
      field: "offers_status",
      headerName: "Offerte",
      width: 90,
      editable: true,
      renderCell: (params) => {
        console.log(params)
        if (params.value === 1 && partnerCode.includes('SEDE')) {
          return (
            <div style={{ textAlign: "center", cursor: "pointer" }} onClick={() => redirectOffers(params.row.vat)}>
              <div style={{ backgroundColor: "blue", width: "18px", height: "18px", borderRadius: "50%", display: "inline-block" }}></div>
            </div>
          );
        } else {
          return (
            <div style={{ textAlign: "center" }}>
              {params.value === 1 ? <div style={{ backgroundColor: "blue", width: "18px", height: "18px", borderRadius: "50%", display: "inline-block" }}></div> : null}
            </div>
          );
        }
      },
    }
    ,
    {
      field: "orders_status",
      headerName: "Ordini",
      width: 90,
      editable: true,
      renderCell: (params) => {
        console.log(partnerCode)
        if (params.value === 1 && partnerCode.includes('SEDE')) {
          return (
            <div style={{ textAlign: "center", cursor: "pointer" }} onClick={() => redirectOrders(params.row.vat)}>
              <div style={{ backgroundColor: "green", width: "18px", height: "18px", borderRadius: "50%", display: "inline-block" }}></div>
            </div>
          );
        } else {
          return (
            <div style={{ textAlign: "center" }}>
              {params.value === 1 ? <div style={{ backgroundColor: "green", width: "18px", height: "18px", borderRadius: "50%", display: "inline-block" }}></div> : null}
            </div>
          );
        }
      },
    },
    {
      field: "actions",
      headerName: "Azioni",
      width: 470,
      sortable: false,
      renderCell: (params) => (
        <div>
          <Link to={`/exclusives/edit/${params.row._id}`}>
            <Button
              size="small"
              variant="outlined"
              sx={{ marginRight: "4px", lineHeight: "0px", height: "31px" }}
            >
              Modifica
            </Button>
          </Link>
          <Button
            size="small"
            variant="outlined"
            onClick={() => handleNotes(params.row._id)}
            sx={{ lineHeight: "0px", height: "31px" }}
          >
            Note1
          </Button>
        </div>
      ),
    },
  ];

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleExpiryChange = (date) => {
    setSearchExpiry(date);
  };

  const handleStatusChange = (event) => {
    setSearchStatus(event.target.value);
  };

  const handlePartnerChange = (event) => {
    setSearchPartner(event.target.value);
  };

  const handleNotes = (id) => {
    setIsPartners(false);
    setIsCreateNote(false);
    setIsExtension(false)
    setIsNotes(true);
    setIsModalOpen(true);
    const selectedRow = rows.find((item) => item._id === id);
    console.log(selectedRow, "::::");
    if (selectedRow) {
      setNotes(selectedRow.notes);
    }
  };

  const requestNote = async (eid) => {
    try {
      setLoader();
      const url = `/requests`;
      const response = await API.post(url, { exclusive_id: eid });
      if (response.data.message == "ok") {
        unsetLoader();
        toaster({text: `${response.data.data}`,},{ type: "success" });
      } else {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "error" }
        );
      }
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  };

  const handlePartners = async (id) => {
    try {
      const selectedRow = rows.find((item) => item._id === id);
      if (selectedRow) {
        setExclusiveId(id);
        console.log(selectedRow);
        setIsNotes(false);
        setIsExtension(false)
        setIsCreateNote(false);
        setIsPartners(true);
        const url = `/share_exclusive/${id}`;
        const response = await API.get(url);
        if (response.data.message == "ok") {
          setIsModalOpen(true); // Open the offers modal
          console.log(response.data.data);
          setPartners(response.data.data);
        } else {
          toaster(
            {
              text: `${response.data.data}`,
            },
            { type: "error" }
          );
          //alert(response.data.data)
        }
      }
    } catch (error) {
      console.log(error);
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  };

  const handlePartnersSubmit = async () => {
    try {
      setLoader();
      const response = await API.post(`/share_exclusive`, {
        exclusive_id: exclusiveId,
        partner_codes: selectedCheckboxes,
      });
      setSelectedCheckboxes([]);
      setPartners([]);
      if (response.data.message == "ok") {
        toaster({text: `${response.data.data}`,},{ type: "success" });
        unsetLoader();
        setIsModalOpen(false);
        fetchData();
      } else {
        unsetLoader();
        toaster({text: `${response.data.data}`,},{ type: "error" });
      }
    } catch (error) {
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
      unsetLoader();
    }
  };

  const handleCreateNote = async (id, partner_code) => {
    try {
      const selectedRow = rows.find((item) => item._id === id);
      if (selectedRow) {
        console.log(selectedRow);
        setExclusiveId(id);
        setExclusivePartnerCode(partner_code);
        setIsNotes(false);
        setIsExtension(false)
        setIsPartners(false);
        setIsCreateNote(true);
        setIsModalOpen(true); // Open the offers modal
      }
    } catch (error) {
      toaster(
        {
          text: `Errore: ${error}`,
        },
        { type: "error" }
      ); // Handle error
    }
  };

  const handleCreateNoteSubmit = async () => {
    try {
      setLoader();
      const response = await API.post(`/notes`, {
        note: noteValue,
        exclusive_id: exclusiveId,
        partner_code: exclusivePartnerCode,
      });
      console.log(response.data); // Handle success
      if (response.data.message == "ok") {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "success" }
        );
        setNoteValue(undefined);
      } else {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "error" }
        ); //alert(response.data.data)
      }
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
    setIsModalOpen(false);
    setTimeout(() => {fetchData();}, 1000);
    //setTimeout(() => {navigate("/exclusives");}, 1000);
  };

  const restoreExclusive = async (eid) => {
    try {
      setLoader();
      const url = `${apiUrl}/exclusives/restore`;
      const response = await API.post(
        url,
        { exclusive_id: eid },
        { headers: { token: `${token}` } }
      );
      if (response.data.message == "ok") {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "success" }
        );
      } else {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "error" }
        );
      }
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (isNotes == true) {
      setIsNotes(false);
    }
    if (isPartners == true) {
      setIsPartners(false);
    }
    if (isCreateNote == true) {
      setIsCreateNote(false);
    }
    if (isExtension == true) {
      setIsExtension(false);
      setIsExtensionOffers(false)
      setIsExtensionOrders(false)
    }
    setNoteValue(undefined);
    setExclusiveId(undefined);
    setExclusivePartnerCode(undefined);
  };

  const handleExportXls = async () => {
    try {
      setLoader();
      const url = `/export_xls/partners`;
      const response = await API.get(url);
      if (response.data.message == "ok") {
        window.open(`${apiUrl}/downloads/affidamenti_partners.xlsx`);
        //toast.success(response.data.data);
        unsetLoader();
      } else {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "error" }
        );
      }
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  };

  const handleExportXlsPartner = async () => {
    try {
      setLoader();
      const url = `/export_xls_partner/${partnerCode}`;
      const response = await API.get(url);
      if (response.data.message == "ok") {
        window.open(`${apiUrl}/downloads/affidamenti_${partnerCode}.xlsx`);
        //toast.success(response.data.data);
        unsetLoader();
      } else {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "error" }
        );
      }
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  };

  const handleCheckboxChange = (event) => {
    const checkboxValue = event.target.value;
    if (event.target.checked) {
      setSelectedCheckboxes((prevSelected) => [...prevSelected, checkboxValue]);
    } else {
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((value) => value !== checkboxValue)
      );
    }
  };

  const handleExclusiveExtension = async (id, partner_code, offers_status, orders_status) => {
    try {
      const selectedRow = rows.find((item) => item._id === id);
      if (selectedRow) {
        console.log(selectedRow);
        console.log(id, partner_code, offers_status, orders_status)
        setExclusiveId(id);
        setExclusivePartnerCode(partner_code);
        setIsExtension(true)
        if(offers_status == 0 && orders_status == 0) {
          setIsExtensionOffers(true)
        }
        if(offers_status == 1 && orders_status == 0) {
          setIsExtensionOrders(true)
        }
        setIsNotes(false);
        setIsPartners(false);
        setIsCreateNote(false);
        setIsModalOpen(true); // Open the offers modal
      }
    } catch (error) {
      toaster(
        {
          text: `Errore: ${error}`,
        },
        { type: "error" }
      ); // Handle error
    }
  };

  const handleExclusiveExtensionSubmit = async () => {
    try {
      if(reasonValue==undefined) {
        toaster(
          {
            text: `Seleziona un motivo per la proroga!`,
          },
          { type: "error" }
        );
        return false;
      }
      if(extensionPeriodValue==undefined) {
        toaster(
          {
            text: `Seleziona una durata per la proroga!`,
          },
          { type: "error" }
        );
        return false;
      }
      //return false;
      setLoader();
      const response = await API.post(`/extend_exclusive`, {
        reason: reasonValue,
        extension_period: extensionPeriodValue,
        exclusive_id: exclusiveId,
        partner_code: exclusivePartnerCode,
      });
      console.log(response.data); // Handle success
      //return false;
      if (response.data.message == "ok") {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "success" }
        );
        setReasonValue(undefined)
        setExtensionPeriodValue(undefined)

      } else {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "error" }
        ); //alert(response.data.data)
      }
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
    setIsModalOpen(false);
    setTimeout(() => {fetchData();}, 1000);
    //setTimeout(() => {navigate("/exclusives");}, 1000);
  };

  const handleChangeReason = (event) => {
    const { name, value } = event.target;
    setReasonValue(value)
  };

  const handleChangeExtensionPeriod = (event) => {
    const { name, value } = event.target;
    setExtensionPeriodValue(value)
  };

  if (!isLoggedIn) return <Signin />;

  // Pass handleNotes function as a prop to the columns array
  const columnsWithActions = columns.map((column) => {
    if (column.field === "actions") {
      return {
        ...column,
        renderCell: (params) => (
          <div>
            {userType === "admin" ? (
              <>
                {params.row.status === 1 ? (
                  <>
                    <Link to={`/exclusives/edit/${params.row._id}`}>
                      <Button
                        size="small"
                        variant="outlined"
                        sx={{
                          marginRight: "4px",
                          lineHeight: "0px",
                          height: "31px",
                        }}
                      >
                        Modifica
                      </Button>
                    </Link>
                    &nbsp;
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => handleNotes(params.row._id)}
                      sx={{ lineHeight: "0px", height: "31px" }}
                    >
                      Note
                    </Button>
                    &nbsp;
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => requestNote(params.row._id)}
                      sx={{ lineHeight: "0px", height: "31px" }}
                    >
                      Richiedi Nota
                    </Button>
                    &nbsp;
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => handlePartners(params.row._id)}
                      sx={{ lineHeight: "0px", height: "31px" }}
                    >
                      Condividi
                    </Button>
                    &nbsp;
                    <Link
                      to={`/offers/create/${params.row._id}/${params.row.partner_code}`}
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        sx={{ lineHeight: "0px", height: "31px" }}
                      >
                        Offerta
                      </Button>
                    </Link>
                  </>
                ) : (
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => restoreExclusive(params.row._id)}
                    sx={{ lineHeight: "0px", height: "31px" }}
                  >
                    Riattiva
                  </Button>
                )}
              </>
            ) : (
              <>

        {params.row.partner_code === partnerCode ? (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    handleCreateNote(params.row._id, params.row.partner_code)
                  }
                  sx={{ lineHeight: "0px", height: "31px" }}
                >
                  Inserisci Nota
                </Button>
            ) : null}
            &nbsp;
             {params.row.partner_code === partnerCode ? (

                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => handleNotes(params.row._id)}
                  sx={{ lineHeight: "0px", height: "31px" }}
                >
                  Note
                </Button>
              ) : null}
                &nbsp;
                {(params.row.offers_status === 0 || params.row.orders_status === 0) && params.row.partner_code === partnerCode ? (
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() =>
                      handleExclusiveExtension(
                        params.row._id,
                        params.row.partner_code,
                        params.row.offers_status,
                        params.row.orders_status
                      )
                    }
                    sx={{ lineHeight: "0px", height: "31px" }}
                  >
                    Richiedi Proroga
                  </Button>
                ) : null}


              </>

            )}

          </div>
        ),
      };
    }
    return column;
  });
  let index = 0;
  return (
    <div>
      <Box>
        <h1>Elenco Affidamenti {target}</h1>
        {userType === "admin" && (
          <div className="customDatePicker" style={{ marginBottom: "10px" }}>
            <TextField
              size="small"
              label="Cerca in Azienda o P.Iva"
              type="search"
              variant="outlined"
              style={{ marginRight: "10px", marginBottom: "10px" }}
              value={searchInput}
              onChange={handleSearchInputChange}
            />

            <Select
              size="small"
              value={searchPartner}
              onChange={handlePartnerChange}
            >
            <MenuItem key="all" value="null">
              Tutti Partner
            </MenuItem>
              {partners.map(partner => (
              <MenuItem key={partner.partner_code} value={partner.partner_code}>
                {partner.name} {partner.lastname}
              </MenuItem>
            ))}
            </Select>
            &nbsp;
            <DatePicker format="DD/MM/YYYY" onChange={handleExpiryChange} />
            &nbsp;
            <Select
              size="small"
              value={searchStatus}
              onChange={handleStatusChange}
            >
              <MenuItem value="null">Tutti gli Stati</MenuItem>
              <MenuItem value="1">Attivi&nbsp;&nbsp;&nbsp;&nbsp;</MenuItem>
              <MenuItem value="0">Scaduti&nbsp;&nbsp;&nbsp;</MenuItem>
            </Select>
            &nbsp;
            <Button
              variant="contained"
              color="primary"
              onClick={handleExportXls}
            >
              Esporta Excel
            </Button>
          </div>
        )}
        {userType === "partner" && (
          <div className="customDatePicker" style={{ marginBottom: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleExportXlsPartner}
            >
              Esporta Excel
            </Button>
          </div>
        )}
        <Box>
        <DataGrid
          className="dataGrid"
          isCellEditable={() => false}
          rows={rows}
          columns={columnsWithActions} // Use the modified columns array
          initialState={{ pagination: { paginationModel: { pageSize: 15 } } }}
          pageSizeOptions={[15]}
          disableRowSelectionOnClick
          disableSelectionOnClick
          disableColumnMenu
          getRowClassName={(params) => {
            return params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
            //params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
          }}
        />
        </Box>
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              height: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "auto",
            }}
          >
            {isNotes &&
              notes.map((noteObject, index) => (
                <div key={index}>
                  {noteObject.note} <br />
                  {moment(noteObject.date_created).format("DD/MM/YYYY")}
                  <hr />
                </div>
              ))}

            {isPartners && (
              <div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Seleziona</TableCell>
                        <TableCell>Nome</TableCell>
                        <TableCell>Cognome</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {partners.map((partnerObject, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Checkbox
                              value={partnerObject.partner_code}
                              checked={selectedCheckboxes.includes(
                                partnerObject.partner_code
                              )}
                              onChange={handleCheckboxChange}
                            />
                          </TableCell>
                          <TableCell>{partnerObject.name}</TableCell>
                          <TableCell>{partnerObject.lastname}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box textAlign="center" mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handlePartnersSubmit()}
                  >
                    Salva
                  </Button>
                </Box>
              </div>
            )}
            {isCreateNote && (
              <div>
                <textarea
                  value={noteValue}
                  onChange={(e) => setNoteValue(e.target.value)}
                  rows={10} // Adjust the number of rows as needed
                  style={{ width: "100%", marginBottom: "10px" }}
                />
                <Box textAlign="center" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateNoteSubmit}
                >
                  Invia
                </Button>
                </Box>
              </div>
            )}
            {isExtension && (
              <div>
                 <Box mt={2}>
                 {isExtensionOffers && (
                    <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="extension-offers">Motivi Richiesta Proroga</InputLabel>
                      <Select
                        labelId="extension-offers"
                        name="reasonValue"
                        value={reasonValue}
                        onChange={handleChangeReason}
                      >
                        <MenuItem value={"Cliente e' in procinto di chiedere la prima offerta"}>Cliente e' in procinto di chiedere la prima offerta</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                 )}
                 {isExtensionOrders && (
                    <Grid item xs={12}>
                    <FormControl fullWidth>
                    <InputLabel id="extension-offers">Motivi Richiesta Proroga</InputLabel>
                      <Select
                        labelId="extension-offers"
                        name="reasonValue"
                        value={reasonValue}
                        onChange={handleChangeReason}
                      >
                      <MenuItem value={"Il cliente chiederà ulteriori offerte"}>Il cliente chiederà ulteriori offerte</MenuItem>
                      <MenuItem value={"Ordini in fase di conclusione"}>Ordini in fase di conclusione</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                 )}
                 </Box>
                 <Box mt={2}>
                 <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="expiry_period-label">Durata Proroga</InputLabel>
                  <Select
                    labelId="expiry_period-label"
                    name="expiry_period"
                    value={extensionPeriodValue}
                    onChange={handleChangeExtensionPeriod}
                  >
                    <MenuItem value={"1"}>1 Mese</MenuItem>
                    <MenuItem value={"2"}>2 Mesi</MenuItem>
                    <MenuItem value={"3"}>3 Mesi</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
                 </Box>
                <Box textAlign="center" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleExclusiveExtensionSubmit}
                >
                  Richiedi Proroga
                </Button>
                </Box>
              </div>
            )}
          </Box>
        </Modal>
      </Box>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </div>
  );
}
