import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyIcon from "@mui/icons-material/Money";
import PaidIcon from "@mui/icons-material/Paid";
import ApiIcon from '@mui/icons-material/Api';
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { Link } from "react-router-dom";

export const mainListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset style={{ fontSize: "1.1rem", textDecoration: "underline", textAlign: "left", fontWeight:"bold"}}>
     Rete Commerciale
    </ListSubheader>
  <React.Fragment>
    <ListItemButton component={Link} to="/partners">
      <ListItemIcon>
        <PeopleAltIcon />
      </ListItemIcon>
      <ListItemText primary="Elenco Partners" />
    </ListItemButton>
    <ListItemButton component={Link} to="/partners/create">
      <ListItemIcon>
        <PersonAddAlt1Icon />
      </ListItemIcon>
      <ListItemText primary="Nuovo Partner" />
    </ListItemButton>
    <ListItemButton component={Link} to="/exclusives">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Elenco Affidamenti" />
    </ListItemButton>
    <ListItemButton component={Link} to="/offers/all">
      <ListItemIcon>
        <MoneyIcon />
      </ListItemIcon>
      <ListItemText primary="Elenco Offerte" />
    </ListItemButton>
    <ListItemButton component={Link} to="/orders/all">
      <ListItemIcon>
        <PaidIcon />
      </ListItemIcon>
      <ListItemText primary="Elenco Ordini" />
    </ListItemButton>
  </React.Fragment>

  <ListSubheader component="div" inset style={{ fontSize: "1.1rem", textDecoration: "underline", textAlign: "left", fontWeight:"bold"}}>
    Sede
    </ListSubheader>
  <React.Fragment>
    <ListItemButton component={Link} to="/admins">
      <ListItemIcon>
        <PeopleAltIcon />
      </ListItemIcon>
      <ListItemText primary="Elenco Admin" />
    </ListItemButton>
    <ListItemButton component={Link} to="/exclusives/admins">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Elenco Affidamenti" />
    </ListItemButton>
    <ListItemButton component={Link} to="/exclusives/create">
      <ListItemIcon>
        <DashboardCustomizeIcon />
      </ListItemIcon>
      <ListItemText primary="Nuovo Affidamento" />
    </ListItemButton>
    <ListItemButton component={Link} to="/offers/admins/all">
      <ListItemIcon>
        <MoneyIcon />
      </ListItemIcon>
      <ListItemText primary="Elenco Offerte" />
    </ListItemButton>
    <ListItemButton component={Link} to="/orders/admins/all">
      <ListItemIcon>
        <PaidIcon />
      </ListItemIcon>
      <ListItemText primary="Elenco Ordini" />
    </ListItemButton>
    <ListItemButton component="a" href="https://dashboard.acubeapi.com/dashboard" target="_blank" rel="noopener noreferrer">
    <ListItemIcon>
     <ApiIcon />
    </ListItemIcon>
    <ListItemText primary="A-Cube" />
  </ListItemButton>
  </React.Fragment>

  </React.Fragment>
);

export const partnerListItems = (
  <React.Fragment>
    {window.innerWidth > 640 && (
      <ListItemButton component={Link} to="/exclusives">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Affidamenti" />
      </ListItemButton>
    )}
    <ListItemButton component={Link} to="/exclusives/create">
      <ListItemIcon>
        <DashboardCustomizeIcon />
      </ListItemIcon>
      <ListItemText primary="Nuovo Affidamento" />
    </ListItemButton>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton>
  </React.Fragment>
);
