import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Box from "@mui/material/Box";
import Signin from "./Signin";
import { useAuth } from "./AuthContext";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "./config/API";
import { useToast } from "./components/Toast";
import { useNavigate } from "react-router-dom";

export default function PartnersEdit() {
  const navigate = useNavigate();
  const [partners, setPartners] = useState([]);
  const {
    isLoggedIn,
    userId,
    token,
    userType,
    partnerCode,
    apiUrl,
    isLoading,
    setLoader,
    unsetLoader,
  } = useAuth();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    _id: "",
    operations_zone: "",
    annual_offers_budget: "",
    annual_orders_budget: "",
    exclusives_limit_number: "",
    name: "",
    lastname: "",
    address: "",
    zipcode: "",
    city: "",
    vat: "",
    phone: "",
    email: "",
    category: "",
    expiry_period: "",
    password: "",
    expiry_period_custom: "",
    subpartners : []
  });
  const toaster = useToast();

  useEffect(() => {
    const fetchPartnerData = async () => {
      try {
        setLoader();
        const response = await API.get(`/partners/${id}`);
        unsetLoader();
        setFormData(response.data.data); 
        console.log(response.data.data)
        if(!('subpartners' in response.data.data)) {
          formData['subpartners'] = []
        }
      } catch (error) {
        unsetLoader();
        console.error("Error fetching partner data:", error);
      }
      const url = `/migrate_exclusive/${userId}`;
        const response = await API.get(url);
        if (response.data.message == "ok") {
          console.log(response.data.data);
          setPartners(response.data.data);
        } else {
          toaster(
            {
              text: `${response.data.data}`,
            },
            { type: "error" }
          );
          //alert(response.data.data)
        }
    };
    fetchPartnerData();
  }, [id]);

  const fieldOrder = [
    "vat",
    "operations_zone",
    "name",
    "lastname",
    "email",
    "password",
    "address",
    "zipcode",
    "city",
    "phone",
    "annual_offers_budget",
    "annual_orders_budget",
    "exclusives_limit_number",
    "category",
    "expiry_period",
  ];

  const fieldLabels = {
    operations_zone: "Zona Operativa",
    name: "Nome",
    lastname: "Cognome",
    address: "Indirizzo",
    password: "Password",
    zipcode: "CAP",
    city: "Città",
    vat: "Partita IVA",
    phone: "Telefono",
    email: "Email",
    category: "Categoria",
    expiry_period: "Scadenza",
    annual_offers_budget: "Budget Annuale Offerte",
    annual_orders_budget: "Budget Annuale Ordini",
    exclusives_limit_number: "Limite Numero Affidamenti",
  };

  function convertEuropeanToNumeric(stringValue) {
    const [integerPart, decimalPart] = stringValue.split(',');
    const cleanIntegerPart = integerPart.replace(/\./g, '');
    const cleanDecimalPart = decimalPart ? decimalPart.replace(/\./g, '') : '';
    const numericValue = cleanDecimalPart ? `${cleanIntegerPart}.${cleanDecimalPart}` : cleanIntegerPart
    return numericValue;
  }

  // Handler for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Check if any field is empty, null, or undefined
    for (const key in formData) {
      if(key != 'expiry_period_custom') {
      if (!formData[key]) {
        toaster(
          {
            text: `${key} non puo' essere vuoto!`,
          },
          { type: "error" }
        );
        return false;
      }
    }
      if(key == 'annual_offers_budget') {
        formData['annual_offers_budget'] = convertEuropeanToNumeric(formData['annual_offers_budget']) //.replace(".","").replace(",",".")
      }
      if(key == 'annual_orders_budget') {
        formData['annual_orders_budget'] = convertEuropeanToNumeric(formData['annual_orders_budget']) //.replace(".","").replace(",",".")
      }
      delete formData['password']
    }
    try {
      setLoader();
      const response = await API.put(`/partners`, formData);
      if (response.data.message == "ok") {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "success" }
        );
        setTimeout(() => {navigate("/partners");}, 1000);
      } else {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "error" }
        );
        //alert(response.data.data)
      }
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
    
  };

  // Handler for input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const delete_item = async (scope, id) => {
    //if(confirm("Sei sicuro di voler cancellare l'offerta?")) {
      try {
        setLoader();
        const response = await API.get(`${apiUrl}/delete_item/${scope}/${id}`);
        console.log(response.data);
        if (response.data.message == "ok") {
          toaster(
            {
              text: `${response.data.data}`,
            },
            { type: "success" }
          );
          setTimeout(() => {navigate("/partners");}, 1000);

        } else if (response.data.message == "error") {
          toaster(
            {
              text: `${response.data.data}`,
            },
            { type: "error" }
          );
        }
        unsetLoader();
        
       
      } catch (error) {
        unsetLoader();
        console.error("Error:", error);
        toaster({text: `Error fetching data: ${error}`,},{ type: "error" });
      }
   //}
  };

  if (!isLoggedIn) return <Signin />;

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <h1>
            Edit Partner {formData.name} {formData.lastname}
          </h1>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {fieldOrder.map((key) => (
                <Grid item xs={12} key={key}>
                  {key === "category" ? (
                    <FormControl fullWidth>
                      <InputLabel id="category-label">Categoria</InputLabel>
                      <Select
                        labelId="category-label"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                      >
                        <MenuItem value="Commerciale">Commerciale</MenuItem>
                        <MenuItem value="Distributore">Distributore</MenuItem>
                        <MenuItem value="Esco">Esco</MenuItem>
                        <MenuItem value="Installatore">Installatore</MenuItem>
                        <MenuItem value="Progettista">Progettista</MenuItem>
                        <MenuItem value="Agente">Agente</MenuItem>
                      </Select>
                    </FormControl>
                  ) : key === "expiry_period" ? (
                    <FormControl fullWidth>
                      <InputLabel id="expiry_period-label">Scadenza Affidamenti Clienti Partner Commerciale</InputLabel>
                      <Select
                        labelId="expiry-period-label"
                        name="expiry_period"
                        value={formData.expiry_period}
                        onChange={handleChange}
                      >
                        <MenuItem value="6">6 Mesi</MenuItem>
                        <MenuItem value="12">12 Mesi</MenuItem>
                        <MenuItem value="24">24 Mesi</MenuItem>
                        <MenuItem value={"custom"}>Libero</MenuItem>
                      </Select>
                    </FormControl>
                  ) : key === "password" ? (
                    <TextField
                      fullWidth
                      label={
                        fieldLabels[key].charAt(0).toUpperCase() +
                        fieldLabels[key].slice(1)
                      } // Capitalize first letter
                      name={key}
                      value={formData[key]}
                      onChange={handleChange}
                      inputProps={{
                        readOnly: true
                        }}
                    />
                  ) : key === "email" ? (
                    <TextField
                      fullWidth
                      label={
                        fieldLabels[key].charAt(0).toUpperCase() +
                        fieldLabels[key].slice(1)
                      } // Capitalize first letter
                      name={key}
                      value={formData[key]}
                      onChange={handleChange}
                      inputProps={{
                        readOnly: true
                        }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      label={
                        fieldLabels[key].charAt(0).toUpperCase() +
                        fieldLabels[key].slice(1)
                      } // Capitalize first letter
                      name={key}
                      value={formData[key]}
                      onChange={handleChange}
                    />
                  )}
                </Grid>
              ))}
              {formData.expiry_period === 'custom' && (
              <Grid item xs={12}>
              <TextField
                value={formData.expiry_period_custom}
                onChange={handleChange}
                fullWidth
                label="Scadenza Affidamenti Clienti Partner Commerciale Libero"
                name="expiry_period_custom"
              />
              </Grid>
              )}
               <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="partners">Responsabile coordinamento commerciale di:</InputLabel>
                  <Select
                    labelId="subpartners"
                    name="subpartners"
                    value={formData.subpartners || []}
                    onChange={handleChange}
                    multiple
                  >
                    {partners.map(partner => (
                    <MenuItem key={partner.partner_code} value={partner.partner_code}>
                      {partner.name} {partner.lastname}
                    </MenuItem>
                  ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ backgroundColor: "#050947" }}
                >
                  Salva
                </Button>
                &nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  style={{ backgroundColor: "#050947" }}
                  onClick={() => {
                    if (window.confirm("Sei sicuro di voler cancellare il partner?")) {
                      delete_item("partners",formData['_id']);
                    }
                  }}
                >
                  Cancella
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Box>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </div>
  );
}
