import React, { useEffect, useState } from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Modal } from "@mui/material";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LOCALSTORAGE_KEY_TOKEN, useAuth } from "./AuthContext";
import Signin from "./Signin";
import API from "./config/API";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useToast } from "./components/Toast";

const columns = [
  {
    field: "order_global_id",
    headerName: "ID Ordine",
    width: 160,
    editable: true,
  },
  {
    field: "offer_id",
    headerName: "ID Offerta",
    width: 200,
    editable: true,
  },
  {
    field: "date_created",
    headerName: "Data",
    width: 160,
    editable: true,
    valueFormatter: (params) => moment(params.value).format("DD/MM/YYYY"),
  },
  {
    field: "company",
    headerName: "Azienda",
    width: 160,
    editable: true,
  },
  {
    field: "vat",
    headerName: "Partita Iva",
    width: 120,
    editable: true,
  },
  {
    field: "product",
    headerName: "Prodotto",
    width: 160,
    editable: true,
  },
  {
    field: "amount",
    headerName: "Valore",
    width: 160,
    valueFormatter: (params) => `${params.value}`,
  },
  {
    field: "partner_name",
    headerName: "Nome Partner",
    width: 160,
    editable: true,
  },
  {
    field: "partner_lastname",
    headerName: "Cognome Partner",
    width: 160,
    editable: true,
  },
  {
    field: "actions",
    headerName: "Azioni",
    width: 160,
    sortable: false,
    renderCell: (params) => (
      <div>
        <Button
          size="small"
          variant="outlined"
          sx={{ marginRight: "4px", lineHeight: "0px", height: "31px" }}
        >
          Modifica
        </Button>
      </div>
    ),
  },
];

export default function OrdersAdmins() {
  const { who } = useParams();
  const [searchInput, setSearchInput] = useState("");
  const [searchCategory, setSearchCategory] = useState("hq");
  const [rows, setRows] = useState([]);
  const [notes, setNotes] = useState([]);
  const [offers, setOffers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOffers, setIsOffers] = useState(false);
  const [isNotes, setIsNotes] = useState(false);
  const { isLoggedIn, partnerCode, token, apiUrl, setLoader, unsetLoader } = useAuth();
  const [target, setTarget] = useState("")
  const [notAll, setNotAll] = useState(false)
  const toaster = useToast();

  useEffect(() => {
    const fetchData = async () => {
      //const localStorageData = localStorage.getItem(LOCALSTORAGE_KEY_TOKEN);
      //const userData = JSON.parse(localStorageData);
      try {
        setLoader();
        if (isLoggedIn == false) {
          return false;
        }
        let _search_input = searchInput == "" ? "null" : searchInput;
        if(who!='all') {
          _search_input = who
          setSearchInput(who)
        }
        const url = `${apiUrl}/orders/${_search_input}/${searchCategory}`;
        const response = await API.get(url);

        const response2 = await API.get(`/vat_to_company/${who}`)
        setTarget(response2.data.data)

        unsetLoader();
        setRows(response.data.data);

        (who == "all") ? setTarget("") : setTarget(`Affidamento Sede ${response2.data.data}`)

        if(who!="all") {setNotAll(true)}

      } catch (error) {
        unsetLoader();
        toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
      }
    };
    fetchData();
  }, [searchInput, searchCategory]);

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSearchCategory(event.target.value);
  };

  if (!isLoggedIn) return <Signin />;

  // Pass handleNotes function as a prop to the columns array
  const columnsWithActions = columns.map((column) => {
    if (column.field === "actions") {
      return {
        ...column,
        renderCell: (params) => (
          <div>
             <Link to={`/orders/edit/${params.row._id}`}>
          <Button
            size="small"
            variant="outlined"
            sx={{ marginRight: "4px", lineHeight: "0px", height: "31px" }}
          >
            Modifica
          </Button>
          
        </Link>
         {/* 
            <Button
              size="small"
              variant="outlined"
              onClick={() => handleNotes(params.row._id)}
              sx={{ lineHeight: "0px", height: "31px" }}
            >
              Note
            </Button>
            &nbsp;
            <Button
            size="small"
            variant="outlined"
            onClick={() => requestNote(params.row._id)}
            sx={{ lineHeight: "0px", height: "31px" }}
          >
            Richedi Nota
          </Button>
          &nbsp;
          <Button
          size="small"
          variant="outlined"
          onClick={() => handleOffers(params.row._id)}
          sx={{ lineHeight: "0px", height: "31px" }}
        >
          Offerte
        </Button> */}
          </div>
        ),
      };
    }
    return column;
  });

  return (
    <div>
      <Box>
        <h1>Elenco Ordini {target}</h1>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { mb: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            size="small"
            label="Cerca"
            type="search"
            variant="outlined"
            style={{ marginRight: "10px", marginBottom: "10px" }}
            value={searchInput}
            onChange={handleSearchInputChange}
            inputProps={{ readOnly: notAll }}
          />
        </Box>
        <Box>
          <DataGrid
            className="dataGrid"
            rows={rows}
            columns={columnsWithActions} // Use the modified columns array
            initialState={{ pagination: { paginationModel: { pageSize: 15 } } }}
            pageSizeOptions={[15]}
            disableRowSelectionOnClick
            disableSelectionOnClick
            disableColumnMenu
            isCellEditable={() => false}
            getRowClassName={(params) => {
              return params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
              //params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
            }}
          />
        </Box>
      </Box>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </div>
  );
}
